/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Home from "../../assets/icons/home.svg";
import Dropdown from "../Dropdown/Dropdown";
import dropdownData from "../../data/dropdownData";
import { Toastr } from "../Toastr/Toastr";
import HamburgerDropdown from "./HamBurger";
import NotificationIcon from "../../assets/icons/notificationNew.svg";
import WebsiteDropdown from "./WebsiteDropdown";
import Notification from "../Notification/NotificationBar";
import "./authorized.scss";
import axios from "../../api/axios";
import notificationMapping from "../../utils/notificationMappings";
import getNotificationInfo from "../../utils/getNotificationInfo";
import {
  incrementActions,
  updateComponent
} from "../../store/slices/trackingSlice";

export default function AuthorizedHeader() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { websitesList } = useSelector((state) => state.generalData);
  const { websiteID } = useSelector((state) => state.generalData);
  const { defaultWebsite } = useSelector((state) => state.generalData);

  const [isOpen, setIsOpen] = useState(false);
  const [notification, setNotification] = useState([]);
  const [notificationCount, setNotificationCount] = useState("");
  const { createdBy: customerId } = useSelector((state) => state.generalData);
  const toggleNotificationBar = () => {
    setIsOpen(!isOpen);
    dispatch(
      updateComponent({
        component: "Header",
        component_label: "Notifications"
      })
    );
    dispatch(incrementActions());
  };
  const [isLoading, setIsLoading] = useState(false);

  // Use the useTranslation hook to get the t function for translation
  const { t } = useTranslation();
  const mappedNotifications = notificationMapping(t);

  // Call the dropdownData function to get the menu data with translations
  const dropdownMenuData = dropdownData(t);
  const notificationApi = async () => {
    try {
      const res = await axios.get(`/vivaWallet/notification/${customerId}`);
      setNotificationCount(res.data.notificationNotRead.length);
      setNotification(res.data.notificationNotRead);
      setIsLoading(false);
    } catch (error) {
      // console.log(error);
      setIsLoading(false);
    }
  };
  const checkCurrentPath = () => {
    return location.pathname === "/mySites" || websitesList?.length < 1;
  };

  useEffect(() => {
    notificationApi();
  }, [websiteID]);

  const handleMarkRead = async (index) => {
    try {
      const temp = notification.map((ele, i) => {
        if (i === index) {
          return { ...ele, read: 1 };
        }
        return ele;
      });
      const dataToSend = temp.map(({ read, notificationId }) => ({
        read,
        notificationId
      }));
      const response = await axios.post(
        "/vivaWallet/notification-read",
        dataToSend
      );
      if (response?.data?.result?.success) {
        notificationApi();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleAllNotifications = async (temp) => {
    try {
      setIsLoading(true);
      const dataToSend = temp.map(({ read, notificationId }) => ({
        read,
        notificationId
      }));
      const response = await axios.post(
        "/vivaWallet/notification-read",
        dataToSend
      );
      if (response?.data?.result?.success) {
        notificationApi();
      }
    } catch (err) {
      setIsLoading(false);
    }
  };

  const handleMarkAllRead = () => {
    const temp = notification.map((ele) => {
      return { ...ele, read: 1 };
    });
    handleAllNotifications(temp);
  };

  const handleOnclickNotification = (notificationText, i) => {
    handleMarkRead(i);
    setIsOpen(false);
    navigate(getNotificationInfo(notificationText, mappedNotifications).link);
  };

  const getManageCampaignList = () => {
    const res = websitesList.find((ele) => ele.id === websiteID);

    if (res?.manage_campaign_user_access && !res?.tag_manager_user_access) {
      return dropdownMenuData.CampaignMenu.filter(
        (dropdown) => dropdown.id === 3
      );
    }
    if (res?.manage_campaign_user_access) {
      return dropdownMenuData.CampaignMenu.filter(
        (dropdown) => dropdown.id !== 1
      );
    }
    if (!res?.tag_manager_user_access) {
      return dropdownMenuData.CampaignMenu.filter(
        (dropdown) => dropdown.id !== 2
      );
    }
    return dropdownMenuData.CampaignMenu;
  };

  return (
    <header className="authorized-header">
      <div className="header-container">
        <div className="header-section flex-between">
          <nav className="dropdown-page">
            <ul
              className={
                checkCurrentPath() ? "flex max-gap" : "normal-gap flex"
              }
            >
              {" "}
              {/* eslint-disable */}
              {websitesList?.length > 0 && defaultWebsite !== null ? (
                <Link to="/analytics">
                  <li>
                    <img className="home-icon" src={Home} alt="home-icon" />
                  </li>
                </Link>
              ) : websitesList?.length > 0 && defaultWebsite === null ? (
                <li onClick={() => Toastr.error(t("common.websiteValidation"))}>
                  <img className="home-icon" src={Home} alt="home-icon" />
                </li>
              ) : (
                <li>
                  <img className="home-icon" src={Home} alt="home-icon" />
                </li>
              )}
              {!checkCurrentPath() && (
                <>
                  {/* Dashboard dropdown */}
                  <li className="dropdown-nav-items">
                    <Dropdown
                      menus={dropdownMenuData.DashboardMenu}
                      placeholder={t("dashboardMenu.dashboard")}
                    />
                  </li>
                  {/* Dashboard dropdown ends */}

                  {/* Add hr */}
                  <hr className="nav-hr" />
                  {/* Add hr ends */}

                  {/* Data and integration dropdown */}
                  <li className="dropdown-nav-items">
                    <Dropdown
                      menus={dropdownMenuData.IntegrationMenu}
                      placeholder={t("integrationMenu.dataIntegration")}
                    />
                  </li>
                  {/* Data and integration dropdown ends */}

                  {/* Add hr */}
                  <hr className="nav-hr" />
                  {/* Add hr ends */}

                  {/* Marketing Settings dropdown */}
                  <li className="dropdown-nav-items">
                    <Dropdown
                      menus={getManageCampaignList()}
                      placeholder={t("campaignMenu.marketingSettings")}
                    />
                  </li>
                  {/* Marketing Settings dropdown ends */}

                  {/* Add hr */}
                  <hr className="nav-hr" />
                  {/* Add hr ends */}
                </>
              )}
              {/* Website dropdown */}
              {location.pathname !== "/mySites" && (
                <li>
                  <WebsiteDropdown />
                </li>
              )}
              {/* Website dropdown ends*/}
            </ul>
          </nav>

          {/* Notification and settings */}
          <div className="hamburger-notification flex">
            {notificationCount > 0 ? (
              <div className="notificationWithCount">
                <img
                  className="notification-count"
                  src={NotificationIcon}
                  alt="notification bell icon"
                  onClick={toggleNotificationBar}
                />
                <div className="count">{notificationCount}</div>
              </div>
            ) : (
              <img
                className="notification-icon"
                src={NotificationIcon}
                alt="notification bell icon"
                onClick={toggleNotificationBar}
              />
            )}

            {isOpen && (
              <Notification
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                notification={notification}
                setNotification={setNotification}
                notificationApi={notificationApi}
                handleMarkAllRead={handleMarkAllRead}
                handleOnclickNotification={handleOnclickNotification}
                isLoading={isLoading}
              />
            )}
            <HamburgerDropdown />
          </div>
          {/* Notification and settings end*/}
        </div>
      </div>
    </header>
  );
}
