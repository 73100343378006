/* eslint-disable */
import React, { useRef, useState, useCallback, useEffect } from "react";
import { Cropper } from "react-advanced-cropper";
import 'react-advanced-cropper/dist/style.css'
import { ButtonDark, ButtonLight } from "../../../../../components/inputElements/buttons/MainButton";
import "../assets/stylesheets/addCampaignEditImageComponent.scss";
import RotateRightIcon from '@mui/icons-material/RotateRight';
import { useTranslation } from "react-i18next";
import axios from "../../../../../api/axios";
import { v4 as uuidv4 } from "uuid";
import close from "../../../../../assets/icons/close.png";
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import Loader from "./Loader";

export default function AddCampaignEditImageComponent({
  selectedImage,
  setEditImageModel,
  websiteID,
  setModifiedImage,
  setLoading,
  Toastr,
  loading
}) {
  const { t } = useTranslation();
  const cropperRef = useRef(null);
  const [preview, setPreview] = useState(null);
  const handleZoomIn = () => {
    if (cropperRef.current) {
      cropperRef.current.zoomImage(1.1);
    }
  };

  const handleZoomOut = () => {
    if (cropperRef.current) {
      cropperRef.current.zoomImage(0.9);
    }
  };

  const move = () => {
    if (cropperRef.current) {
      cropperRef.current.moveImage(50, 100); // move x = 50, y = 100
    }
  }

    const flip = (horizontal, vertical) => {
      if (cropperRef.current) {
          cropperRef.current.flip(horizontal, vertical);
      }
  };
  const onRotate = (angle) => {
      if (cropperRef.current) {
          cropperRef.current.rotateImage(angle);
      }
  };

    const updatePreview = () => {
        if (cropperRef.current) {
          const canvas = cropperRef.current.getCanvas();
          if (canvas) {
            setPreview(canvas.toDataURL());
          }
        }
      };

    const onUpload = () => {
        const canvas = cropperRef.current?.getCanvas();
        if (canvas) {
          setLoading(true)
          const form = new FormData();
          canvas.toBlob(async(blob) => {
            if (blob) {
              const uniqueName = uuidv4()
              const file = new File([blob], `${uniqueName}_${selectedImage?.name}`, { type: blob.type });
              form.append('file', file);
              form.append("website_id", websiteID);
            try {
              const response = await axios.post("wiget/uploadImage", form, {
                headers: {
                  "Content-Type": "multipart/form-data"
                }
              });
              Toastr.success(t("Success"));
              if(response && response?.data && response?.data?.success) {
                setModifiedImage({
                  src:response?.data?.imageUrls[0]
                })
                setLoading(false)
                setEditImageModel(false)
              }
            } catch (error) {
              setLoading(false)
              console.log("error...", error)
              throw  error
            }
              
            
            }
          }, 'image/jpeg');
        }
      };
    return (
      <div className="add-campaign-edit-image">
      <div
          className="close-img-div"
          onClick={() => {
            setEditImageModel(false);
          }}
          role="button"
          tabIndex={0}
          onKeyDown={(e) => {
            // Handling keyboard events
            if (e.key === "Enter" || e.key === " ") {
              setEditImageModel(false);
            }
          }}
        >
          <img src={close} className="close-img" />
        </div>
        <div 
        className="editor-container"
        >
          <div 
          className="editor-content"
          >
            <div 
            className="cropper-container"
            >
              <div 
              className="tools-panel"
              >
                <div 
                className="tool-button"
                onClick={handleZoomIn}>
                  <ZoomInIcon />
                </div>
                <div 
                className="tool-button"
                onClick={handleZoomOut}>
                  <ZoomOutIcon />
                </div>
                <div 
                className="tool-button"
                onClick={() => onRotate(90)}>
                  <RotateRightIcon />
                </div>
              </div>
              <Cropper
                ref={cropperRef}
                src={selectedImage?.src}
                onChange={updatePreview}
                quality={100}
                className="cropper"
                stencilProps={{
                  movable: true,
                  resizable: true,
                  aspectRatio: 'auto',
                  imageRestriction:"stencil"
                }}
              />

            </div>
            <div 
            className="preview-container"
            >
              {preview && (
                <div>
                  <h3>{t("manageCampaign.preview")}:</h3>
                  <div 
                  className="preview-image-container"
                  style={{ position: 'relative' }}>
                    <img 
                      src={preview} 
                      alt="Preview" 
                    />
                    <div 
                    className="dimensions-badge"
                    >
                      {cropperRef.current?.getCanvas()?.width || 0} × {cropperRef.current?.getCanvas()?.height || 0}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          {
          loading ?  <div 
          className="loader-container"
          >
      <Loader  text={t("manageCampaign.generatingImages")} />
    </div> : 
          <div 
          className="buttons-container"
          >
            <ButtonLight
              buttonText={t("common.cancelButton")}
              onClick={() => setEditImageModel(false)}
            />
            <ButtonDark
              buttonText={t("common.saveButton")}
              onClick={onUpload}
            />
          </div>
}
        </div>
      </div>
    
    );
}

