import { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import "./assets/stylesheets/feedback.scss";
import { ButtonDark } from "../../../components/inputElements/buttons/MainButton";
import attchmentsIcon from "../../../assets/icons/upload.svg";
import closeIcon from "../../../assets/icons/closeButton.svg";
import { Toastr } from "../../../components/Toastr/Toastr";
import saveFeedback from "./api/feedbackApi";

function LabelInputSet({ children }) {
  return <div className="flex-column">{children}</div>;
}

export default function Index() {
  const { t } = useTranslation();
  const { websiteID, createdBy } = useSelector((state) => state.generalData);
  const [feedback, setFeedback] = useState("");
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fileKey, setFileKey] = useState(Date.now());

  const handleFeedback = async () => {
    if (file && file.size > 10 * 1024 * 1024) {
      Toastr.error(t("common.fileSizeRestriction"));
      return;
    }
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("customer_id", createdBy);
      formData.append("website_id", websiteID);
      formData.append("feedback", feedback);
      formData.append("file", file);
      const resp = await saveFeedback(formData);
      if (resp?.data?.success) {
        Toastr.success(t("common.thanksNoteForFeedback"));
        setFile(null);
        setFeedback("");
      } else {
        Toastr.error(t("addScript.somethingWentWrong"));
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("error", error);
    }
  };

  const handleFileUpload = (event) => {
    setFile(event.target.files[0]);
    setFileKey(Date.now());
  };
  const handleFile = () => {
    setFile(null);
  };

  return (
    <div className="feedback-container">
      <div className="header-content flex-between">
        <div className="modules-heading">{t("settings.feedback")}</div>
      </div>
      <div>
        <LabelInputSet>
          <textarea
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
            id="feedback"
            className="common-input add-tag-input-label"
            placeholder={t("common.enterFeedback")}
            rows={8}
          />
        </LabelInputSet>
      </div>
      <div className="img-button">
        <div className="inputContainer">
          <div className="flex-vertical gap-10">
            <img
              src={attchmentsIcon}
              alt="attachments"
              className="attachment-icon"
            />
            <span className="file-text"> {t("common.uploadFile")}</span>
            <span className="drag-file-text">
              {t("uploadImages.drag&Drop")}
            </span>
          </div>
          <input
            key={fileKey} // Forces input reset
            type="file"
            // accept=".jpg, .jpeg, .png, "
            accept="image/*"
            onChange={(e) => handleFileUpload(e)}
            multiple
          />
        </div>
      </div>
      {file && (
        <div className="flex-justify-center image-text-cont">
          <div>
            {file && (
              <p className="image-text">
                {`${t("common.fileName")}:`}
                <span className="image-text-heading">
                  {/* {file?.name.split(".")[0]} */}
                  {file?.name}
                </span>
              </p>
            )}
          </div>
          <button
            type="button"
            onClick={() => handleFile()}
            className="close-button"
          >
            <img src={closeIcon} alt="Close" className="close-img-style" />
          </button>
        </div>
      )}
      <div className="flex-center">
        <ButtonDark
          buttonText={t("common.submit")}
          onClick={handleFeedback}
          loader={loading}
          disabled={feedback === ""}
        />
      </div>
    </div>
  );
}

LabelInputSet.propTypes = {
  children: PropTypes.element
};
