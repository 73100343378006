/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import AddBarGraph from "../../../components/ChartComponents/GeneralBarGraph";
import WorldMap from "../../../components/ChartComponents/WorldMap";
import DonutChart from "../../../components/ChartComponents/DonutChart";
import NoDataFound from "../../Placeholders/NoDataFound";
import HeatMapComponent from "../../../components/HeatMap";
import Insights from "../../../components/Insights";
import MultiBarChart from "../../../components/ChartComponents/MultiBarChart";
import LineChart from "../../DashBoard/DashBoardLandingPage/LineChart";

export default function GeneralPMSDashboard({
  insightsTitle,
  isCompare,
  selectedFilters,
  dashboard,
  isHeatmapLoading,
  heatmapData,
  selectedHeatMap,
  updateSelectedFilter
}) {
  const {
    allData,
    insights,
    visitorsLineData,
    // personaData,
    countriesData,
    roomsData,
    channelsData,
    daysOfStayData,
    adPartnerData,
    adMediumData,
    adCampaignData,
    monthlyRequestData,
    monthlyBookingData,
    ageData,
    spendData,
    genderData,
    typeData,
    guestType,
    bookingGroup,
    pmsRevenueCalculations
  } = useSelector((state) => state.PMSData);

  const commonProps = {
    isCompare,
    dashboard,
    filterValues: selectedFilters
  };

  const { t } = useTranslation();

  if (allData.length > 0)
    return (
      <>
        <Insights
          isCompare={isCompare}
          insightsTitle={insightsTitle}
          insights={insights}
        />
        {/* <LineChart
          revenue={visitorsLineData?.revenue}
          bookings={visitorsLineData?.bookings}
          requests={visitorsLineData?.requests}
          title={t("PMSPage.lineChartTitle")}
        /> */}
        <MultiBarChart
          title={t("PMSPage.lineChartTitle")}
          filterTitle={t("common.date")}
          labels={visitorsLineData?.labels}
          data1={{
            dataset: visitorsLineData?.requests,
            label: t("PMSPage.requests")
          }}
          data2={{
            dataset: visitorsLineData?.bookings,
            label: t("PMSPage.bookings")
          }}
          data3={{
            dataset: visitorsLineData?.revenue,
            label: t("PMSPage.revenue")
          }}
          {...commonProps}
        />
        {/* HEAT MAP  */}
        <HeatMapComponent
          title={t("heatmap.bookingHeatmap")}
          loading={isHeatmapLoading}
          selectedHeatMap={selectedHeatMap}
          heatmapData={heatmapData}
        />
        {/* HEAT MAP ends  */}

        <LineChart
          labels={pmsRevenueCalculations?.labels}
          views={pmsRevenueCalculations?.selectedYear}
          visitors={pmsRevenueCalculations?.lastYearData}
          title={t("PMSPage.revenuecomparision")}
        />

        {/* PERSONA */}
        {/* <StackedChart
        title={t("PMSPage.personaTitle")}
        views={personaData?.views}
        visitors={personaData?.visitors}
        labels={personaData?.labels}
      /> */}
        {/* Persona description  */}

        <div className="grid half-column-grid general-bar-graph-height">
          <AddBarGraph
            title={t("PMSPage.requestTypeTitle")}
            subtitle={t("PMSPage.type")}
            labels={typeData?.labels}
            data={{
              tooltip: t("PMSPage.requests"),
              values: typeData?.requests
            }}
            numBars={
              typeData?.labels?.length < 6 ? typeData?.labels?.length : 6
            }
            columnData={[
              {
                name: t("PMSPage.requests"),
                diff: typeData?.requests,
                number: true
              }
            ]}
            {...commonProps}
          />
          <AddBarGraph
            title={t("PMSPage.roomsTitle")}
            subtitle={t("PMSPage.roomType")}
            labels={roomsData?.labels}
            data={{
              tooltip: t("PMSPage.requests"),
              values: roomsData?.requests
            }}
            numBars={
              roomsData?.labels?.length < 6 ? roomsData?.labels?.length : 6
            }
            columnData={[
              {
                id: 1,
                name: t("PMSPage.roomReq"),
                diff: roomsData?.roomReq,
                number: true
              },
              {
                id: 2,
                name: t("PMSPage.requests"),
                diff: roomsData?.requests,
                number: true
              },
              {
                id: 3,
                name: t("PMSPage.bookings"),
                diff: roomsData?.bookings,
                number: true
              }
            ]}
            {...commonProps}
          />
        </div>
        <div className="grid half-column-grid general-bar-graph-height">
          <AddBarGraph
            title={t("PMSPage.channelTitle")}
            subtitle={t("PMSPage.sources")}
            labels={channelsData?.labels}
            data={{
              tooltip: t("PMSPage.requests"),
              values: channelsData?.requests
            }}
            numBars={
              channelsData?.labels?.length < 6
                ? channelsData?.labels?.length
                : 6
            }
            columnData={[
              {
                id: 1,
                name: t("PMSPage.requests"),
                diff: channelsData?.requests,
                number: true
              },
              {
                id: 2,
                name: t("PMSPage.bookings"),
                diff: channelsData?.bookings,
                number: true
              }
            ]}
            {...commonProps}
          />
          <AddBarGraph
            title={t("PMSPage.daysOfStayTitle")}
            subtitle={t("PMSPage.days")}
            labels={daysOfStayData?.labels}
            data={{
              tooltip: t("PMSPage.requests"),
              values: daysOfStayData?.requests
            }}
            numBars={
              daysOfStayData?.labels?.length < 6
                ? daysOfStayData?.labels?.length
                : 6
            }
            columnData={[
              {
                id: 1,
                name: t("PMSPage.requests"),
                diff: daysOfStayData?.requests,
                number: true
              },
              {
                id: 2,
                name: t("PMSPage.roomReq"),
                diff: daysOfStayData?.roomReq,
                number: true
              },
              {
                id: 3,
                name: t("PMSPage.bookings"),
                diff: daysOfStayData?.bookings,
                number: true
              }
            ]}
            {...commonProps}
          />
        </div>

        <div className="grid half-column-grid general-bar-graph-height">
          <AddBarGraph
            title={t("PMSPage.guestTypeTitle")}
            subtitle={t("PMSPage.sources")}
            labels={guestType?.labels}
            data={{
              tooltip: t("PMSPage.requests"),
              values: guestType?.requests
            }}
            numBars={
              guestType?.labels?.length < 6 ? guestType?.labels?.length : 6
            }
            columnData={[
              {
                id: 1,
                name: t("PMSPage.requests"),
                diff: guestType?.requests,
                number: true
              },
              {
                id: 2,
                name: t("PMSPage.bookings"),
                diff: guestType?.bookings,
                number: true
              }
            ]}
            {...commonProps}
          />
          <AddBarGraph
            title={t("PMSPage.bookingGroupTitle")}
            subtitle={t("PMSPage.sources")}
            labels={bookingGroup?.labels}
            data={{
              tooltip: t("PMSPage.requests"),
              values: bookingGroup?.requests
            }}
            numBars={
              bookingGroup?.labels?.length < 6
                ? bookingGroup?.labels?.length
                : 6
            }
            columnData={[
              {
                id: 1,
                name: t("PMSPage.requests"),
                diff: bookingGroup?.requests,
                number: true
              },
              {
                id: 2,
                name: t("PMSPage.bookings"),
                diff: bookingGroup?.bookings,
                number: true
              }
            ]}
            {...commonProps}
          />
        </div>

        <div className="grid grid-template general-bar-graph-height">
          <AddBarGraph
            title={t("PMSPage.adMediumTitle")}
            subtitle={t("PMSPage.sources")}
            labels={adMediumData?.labels}
            data={{
              tooltip: t("PMSPage.requests"),
              values: adMediumData?.requests
            }}
            numBars={
              adMediumData?.labels?.length < 6
                ? adMediumData?.labels?.length
                : 6
            }
            columnData={[
              {
                id: 1,
                name: t("PMSPage.requests"),
                diff: adMediumData?.requests,
                number: true
              },
              {
                id: 2,
                name: t("PMSPage.bookings"),
                diff: adMediumData?.bookings,
                number: true
              }
            ]}
            {...commonProps}
          />
          <AddBarGraph
            title={t("PMSPage.adCampaignTitle")}
            subtitle={t("PMSPage.sources")}
            labels={adCampaignData?.labels}
            data={{
              tooltip: t("PMSPage.requests"),
              values: adCampaignData?.requests
            }}
            numBars={
              adCampaignData?.labels?.length < 6
                ? adCampaignData?.labels?.length
                : 6
            }
            columnData={[
              {
                id: 1,
                name: t("PMSPage.requests"),
                diff: adCampaignData?.requests,
                number: true
              },
              {
                id: 2,
                name: t("PMSPage.bookings"),
                diff: adCampaignData?.bookings,
                number: true
              }
            ]}
            {...commonProps}
          />
          <AddBarGraph
            title={t("PMSPage.adPartnerTitle")}
            subtitle={t("PMSPage.sources")}
            labels={adPartnerData?.labels}
            data={{
              tooltip: t("PMSPage.requests"),
              values: adPartnerData?.requests
            }}
            numBars={
              adPartnerData?.labels?.length < 6
                ? adPartnerData?.labels?.length
                : 6
            }
            columnData={[
              {
                id: 1,
                name: t("PMSPage.requests"),
                diff: adPartnerData?.requests,
                number: true
              },
              {
                id: 2,
                name: t("PMSPage.bookings"),
                diff: adPartnerData?.bookings,
                number: true
              }
            ]}
            {...commonProps}
          />
        </div>
        <div className="grid half-column-grid general-bar-graph-height">
          <AddBarGraph
            title={t("PMSPage.monthlyReqTitle")}
            subtitle={t("PMSPage.arrival")}
            labels={monthlyRequestData?.labels}
            data={{
              tooltip: t("PMSPage.requests"),
              values: monthlyRequestData?.requests
            }}
            numBars={
              monthlyRequestData?.labels?.length < 6
                ? monthlyRequestData?.labels?.length
                : 6
            }
            columnData={[
              {
                id: 1,
                name: t("PMSPage.requests"),
                diff: monthlyRequestData?.requests,
                number: true
              },
              {
                id: 2,
                name: t("PMSPage.roomReq"),
                diff: monthlyRequestData?.roomReq,
                number: true
              }
            ]}
            {...commonProps}
          />
          <AddBarGraph
            title={t("PMSPage.monthBookingTitle")}
            subtitle={t("PMSPage.arrival")}
            labels={monthlyBookingData?.labels}
            data={{
              tooltip: t("PMSPage.bookings"),
              values: monthlyBookingData?.bookings
            }}
            numBars={
              monthlyBookingData?.labels?.length < 6
                ? monthlyBookingData?.labels?.length
                : 6
            }
            columnData={[
              {
                id: 1,
                name: t("PMSPage.bookings"),
                diff: monthlyBookingData?.bookings,
                number: true
              },
              {
                id: 2,
                name: t("PMSPage.roomBooking"),
                diff: monthlyBookingData?.roomBook,
                number: true
              }
            ]}
            {...commonProps}
          />
        </div>
        <WorldMap
          data={countriesData?.requests}
          labels={countriesData.labels}
          title={t("PMSPage.worldTitle")}
          selectedFilters={selectedFilters}
          updateFilters={updateSelectedFilter}
        />
        <div className="grid grid-template general-bar-graph-height">
          <AddBarGraph
            title={t("PMSPage.ageTitle")}
            subtitle={t("PMSPage.ageTitle")}
            labels={ageData?.labels}
            data={{ tooltip: t("PMSPage.requests"), values: ageData?.requests }}
            numBars={ageData?.labels?.length < 6 ? ageData?.labels?.length : 6}
            columnData={[
              {
                id: 1,
                name: t("PMSPage.requests"),
                diff: ageData?.requests,
                number: true
              },
              {
                id: 2,
                name: t("PMSPage.bookings"),
                diff: ageData?.bookings,
                number: true
              }
            ]}
            {...commonProps}
          />
          <AddBarGraph
            title={t("PMSPage.spendTitle")}
            subtitle={t("PMSPage.euros")}
            labels={spendData?.labels}
            data={{
              tooltip: t("PMSPage.requests"),
              values: spendData?.requests
            }}
            numBars={
              spendData?.labels?.length < 6 ? spendData?.labels?.length : 6
            }
            columnData={[
              {
                id: 1,
                name: t("PMSPage.requests"),
                diff: spendData?.requests,
                number: true
              },
              {
                id: 2,
                name: t("PMSPage.bookings"),
                diff: spendData?.bookings,
                number: true
              }
            ]}
            {...commonProps}
          />
          <DonutChart
            title={t("PMSPage.genderTitle")}
            labels={genderData?.labels}
            dataSet={genderData?.requests}
            selectedFilters={selectedFilters}
            updateFilters={updateSelectedFilter}
          />
        </div>
        <div className="grid grid-template">
          {/* <AddBarGraph
            title={t("PMSPage.avgPriceTitle")}
            subtitle={t("PMSPage.euros")}
            labels={avgPriceData?.labels}
            data={avgPriceData?.dataset}
            requests={avgPriceData?.dataset}
            numBars={
              avgPriceData?.labels?.length < 6
                ? avgPriceData?.labels?.length
                : 6
            }
          /> */}
        </div>
      </>
    );

  return <NoDataFound />;
}

GeneralPMSDashboard.propTypes = {
  isCompare: PropTypes.bool,
  selectedFilters: PropTypes.oneOfType([Object]),
  insightsTitle: PropTypes.oneOfType([Object]),
  dashboard: PropTypes.string,
  isHeatmapLoading: PropTypes.bool,
  heatmapData: PropTypes.oneOfType([PropTypes.array]),
  selectedHeatMap: PropTypes.string,
  updateSelectedFilter: PropTypes.func
};
