/* eslint-disable no-console */
import Axios from "axios";
import storage from "../utils/storage";

/* eslint-disable no-param-reassign */
function authRequestInterceptor(config) {
  const token = storage.getToken();
  // console.log("token", token);

  config.headers.authorization = token;
  config.headers.Accept = "application/json";

  // console.log("Request Config:", config); // Log the request config
  // console.log("Using token:", token); // Log the token being used

  return config;
}
/* eslint-enable no-param-reassign */

const axios = Axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL
});

axios.interceptors.request.use(authRequestInterceptor);

export default axios;
