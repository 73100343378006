/* eslint-disable */
import React from "react";
import Selectpersona from "../../../../../assets/icons/Selectpersona.png";
import "../assets/stylesheets/selectPersona.scss";
import Personatile from "../../../../../assets/icons/Personatile.png";
import { useDispatch } from "react-redux";
import {
  incrementActions,
  updateComponent
} from "../../../../../store/slices/trackingSlice";

export default function SelectPersona({
  isHoveredPersona,
  handleMouseEnterPersona,
  handleMouseLeavePersona,
  selectedPersona,
  t,
  newPersonaTypes,
  handlePersonaChange,
  personas,
  selectedPersonas,
  setSelectedPersonas,
  handleCheckboxChange,
  multiSelectTypes
}) {
  const dispatch = useDispatch();

  return (
    <div
      className={`select-types ${
        isHoveredPersona || multiSelectTypes?.length > 0 ? "isPersona" : ""
      }`}
      onMouseEnter={handleMouseEnterPersona}
      onMouseLeave={handleMouseLeavePersona}
      style={{
        width: "370px",
        marginRight: "20px"
      }}
    >
      <div className="step-text">{t("manageCampaign.step")} 3</div>

      {multiSelectTypes?.length > 0 ? (
        <div>
          {/* <div className="select-types-text">
            A. {t("manageCampaign.selectType")}
          </div> */}
          {/* Dropdown */}
          {/* <div className="types-dropdown">
            <DropdownComponent
              value={
                selectedPersona || t("tagManager.addTagForm.selectPlaceholder")
              }
              options={newPersonaTypes}
              onChange={handlePersonaChange}
              isRequired={false}
              defaultValue={t("tagManager.addTagForm.selectPlaceholder")}
            />
          </div> */}
          {/* Dropdown ends */}

          <div className="select-types-text">
            {t("manageCampaign.selectPersona")}
          </div>
          {/* Checkbox */}
          <div
            style={{
              maxHeight: "490px",
              overflowY: "auto",
              paddingTop: "10px"
            }}
          >
            {/* Select All Checkbox */}
            {/* <div
              className={`flex-between persona-checkbox ${
                selectedPersonas.length === personas.length ? "isSelected" : ""
              }`}
            >
              <div className="flex-center-align">
                <img
                  src={Personatile}
                  alt="All Persona"
                  className="persona-icon"
                />

                <span
                  className={`persona-name ${
                    selectedPersonas.length === personas.length
                      ? "selected"
                      : ""
                  }`}
                >
                  {t("manageCampaign.allPersonas")}
                </span>
              </div>

              <input
                type="checkbox"
                checked={selectedPersonas?.length === personas?.length}
                onChange={() => {
                  if (selectedPersonas?.length === personas?.length) {
                    // If all are selected, deselect all
                    setSelectedPersonas([]); // Clear selection
                    dispatch(
                      updateComponent({
                        component: "Select persona",
                        component_label: "Unselect all"
                      })
                    );
                    dispatch(incrementActions());
                  } else {
                    // Otherwise, select all
                    setSelectedPersonas(personas.map((p) => p.persona)); // Set selection to all personaIds
                    dispatch(
                      updateComponent({
                        component: "Select persona",
                        component_label: "Select all"
                      })
                    );
                    dispatch(incrementActions());
                  }
                }}
              />
            </div> */}

            {personas.map((persona) => (
              <div
                className={`flex-between persona-checkbox ${
                  selectedPersonas?.includes(persona?.persona)
                    ? "isSelected"
                    : ""
                }`}
              >
                <div key={persona.persona} className="flex-center-align ">
                  <img
                    src={Personatile}
                    alt={persona.persona_label}
                    className="persona-icon"
                  />

                  <span
                    className={`persona-name ${
                      selectedPersonas?.includes(persona?.persona)
                        ? "selected"
                        : ""
                    }`}
                  >
                    {persona.persona_label}
                  </span>
                </div>

                <input
                  type="checkbox"
                  checked={selectedPersonas.includes(persona.persona)}
                  onChange={() =>
                    handleCheckboxChange(persona.persona, persona.persona_label)
                  }
                />
              </div>
            ))}
          </div>
          {/* Checkbox ends */}
        </div>
      ) : (
        <div className="select-types-text">Select persona</div>
      )}

      {multiSelectTypes?.length == 0 && (
        <div className="flex-center">
          <img src={Selectpersona} />
        </div>
      )}
    </div>
  );
}
