import axios from "../../../../../api/axios";

export const getCampaignDetails = (websiteId) =>
  axios.get(`/wiget/getCampaignDetails/${websiteId}`);

export const getCategorisedImage = (payload) =>
  axios.post("/wiget/getCategorisedImage", payload);

export const generateTemplate = (payload) =>
  // axios.post("/template/generate-template", payload);
  axios.post("/template/generate-ads-template", payload);

export const generateMultipleDimensions = (payload) =>
  axios.post("/template/generate-mutliple-dimensions", payload);

export const addCampaignImages = (payload) =>
  axios.post("/wiget/addCampaignImages", payload);

export const getAllCampaigns = (websiteId) =>
  axios.get(`/wiget/getCampaignList/${websiteId}`);

export const changeCampaignStatus = (payload) =>
  axios.put("/wiget/campaignStatus", payload);

export const deleteCampaigns = (payload) =>
  axios.post("wiget/deleteCampaign", payload);

export const getCampaignNameExist = (payload) =>
  axios.post("/wiget/campaignName", payload);
