/* eslint-disable */
import React, { useEffect, useState } from "react";
import close from "../../../../../assets/icons/close.png";
import { useTranslation } from "react-i18next";
import {
  ButtonDark,
  ButtonLight
} from "../../../../../components/inputElements/buttons/MainButton";
import "../assets/stylesheets/usedImageDesignPreviewComponent.scss";
import { useLocation, useNavigate } from "react-router-dom";
import AuthorizedHeader from "../../../../../components/layouts/AuthorizedHeader";
import ReactJsxParser from "react-jsx-parser";
import { generateMultipleDimensions } from "../../AddCampaign/api/addCampaignAPI";
import { Toastr } from "../../../../../components/Toastr/Toastr";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../AddCampaign/components/Loader";
import { handleGetCampaignNameExist } from "../../../services/addCampaignServices";
import { Dialog } from "@mui/material";
import AddCampaignEditImageComponent from "../../AddCampaign/components/AddCampaignEditImageComponent"
import DropdownComponent from "../../../../../components/inputElements/selectInput/DropdownComponent";
import { incrementActions, updateComponent } from "../../../../../store/slices/trackingSlice";

export default function UsedImageDesignPreviewComponent() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const {
    selectedChannels,
    multiSelectTypes,
    selectedPersonas,
    selectedImage,
    isCampaignForMobile,
    designPreviewCampaignName,
    designPreviewAdRedirectUrl,
    designPreviewPosition,
    navigationFrom
  } = location.state || {};

  const channelName = selectedChannels?.map((each)=>each.channelName)
  const { websiteID } = useSelector((state) => state.generalData);

  const [campaignName, setCampaignName] = useState("");
  const [adRedirectUrl, setAdRedirectUrl] = useState("");
  const [ctaButtonUrl, setCtaButtonUrl] = useState("");
  const [useSameUrl, setUseSameUrl] = useState(false);
  const [errors, setErrors] = useState("");

  const [loading, setLoading] = useState(false);

  const [editImageModel, setEditImageModel] = useState(false)

  const [modifiedImage, setModifiedImage] = useState(selectedImage)

  const positionOptions = [
    {
      id: 1,
      lang: "English",
      text: "Center",
      label: "Center",
      value: "center"
    },
    {
      id: 2,
      lang: "English",
      text: "Top Left",
      label: "Top Left",
      value: "topLeft"
    },
    {
      id: 3,
      lang: "English",
      text: "Top Center",
      label: "Top Center",
      value: "topCenter"
    },
    {
      id: 4,
      lang: "English",
      text: "Top Right",
      label: "Top Right",
      value: "topRight"
    },
    {
      id: 5,
      lang: "English",
      text: "Bottom Left",
      label: "Bottom Left",
      value: "bottomLeft"
    },
    {
      id: 6,
      lang: "English",
      text: "Bottom Center",
      label: "Bottom Center",
      value: "bottomCenter"
    },
    {
      id: 7,
      lang: "English",
      text: "Bottom Right",
      label: "Bottom Right",
      value: "bottomRight"
    },
    {
      id: 8,
      lang: "English",
      text: "Center Left",
      label: "Center Left",
      value: "centerLeft"
    },
    {
      id: 9,
      lang: "English",
      text: "Center Right",
      label: "Center Right",
      value: "centerRight"
    }
  ];

  const [position, setPosition] = useState("center");

  const mobilePositionOptions = [
    {
      id: 1,
      lang: "English",
      text: "Center",
      label: "Center",
      value: "center"
    },
    {
      id: 2,
      lang: "Bottom",
      text: "Bottom",
      label: "Bottom",
      value: "bottom"
    }
  ]

  const [mobilePosition, setMobilePosition] = useState("center");


  const channelNames = selectedChannels?.map((channel) =>
    channel.channelName.toLowerCase()
  );

  const handleInputChange =
    (setter, fieldName, useSameUrl = false, setCtaButtonUrl = null) =>
    (e) => {
      const newValue = e.target.value;

      // Set the value for the current field
      setter(newValue);

      // Clear the errors for the current field
      setErrors((prevErrors) => ({ ...prevErrors, [fieldName]: undefined }));

      // // Check if the field is 'adRedirectUrl' and handle the special logic
      if (fieldName === "adRedirectUrl" && useSameUrl && setCtaButtonUrl) {
        setCtaButtonUrl(newValue);
      }
    };

  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    setUseSameUrl(isChecked);

    if (isChecked) {
      setCtaButtonUrl(adRedirectUrl);
    } else {
      setCtaButtonUrl("");
    }
  };

  const  normalizeUrl = (url) => {
    // Check if the URL starts with http://, https://, or www.

    // If the URL starts with www. but doesn't have http:// or https://, prepend http://
    if (/^www\./i.test(url) && !/^https?:\/\//i.test(url)) {
          url = 'http://' + url;
      }
      else if (!/^https?:\/\//i.test(url) && !/^www\./i.test(url)) {
        // If no protocol, add http:// as default
        url = 'http://' + url;
    }
    return url;
}

  const validateForm = () => {
    let newErrors = {};

    const trimmedCampaignName = campaignName.trim();
    const trimmedAdRedirectUrl = adRedirectUrl.trim();

    const urlPattern = new RegExp(
      /^(https?:\/\/)?(www\.)?([a-zA-Z0-9\-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$/
    );

    if (!trimmedCampaignName) {
      newErrors.campaignName = (
        <p className="input-error-text">
          {t("manageCampaign.campaignNameRequired")}
        </p>
      );
    }

    if (!trimmedAdRedirectUrl) {
      newErrors.adRedirectUrl = (
        <p className="input-error-text">{t("manageCampaign.adUrlRequired")}</p>
      );
    }

    if (trimmedAdRedirectUrl && !urlPattern.test(trimmedAdRedirectUrl)) {
      newErrors.adRedirectUrl = (
        <p className="input-error-text">{t("manageCampaign.adUrlValid")}</p>
      );
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {

      const modifiedeRedirectUrl = normalizeUrl(adRedirectUrl)

      setErrors("");
      // generateMultipleDimensions();

      await handleGetCampaignNameExist({
        websiteID:websiteID,
        selectedChannels: selectedChannels,
        campaignName: campaignName,
        adRedirectUrl: modifiedeRedirectUrl,
        multiSelectTypes,
        selectedPersonas,
        selectedImageNew: modifiedImage ? modifiedImage : selectedImage,
        isCampaignForMobile,
        position: isCampaignForMobile ? mobilePosition: position,
        setErrors,
        setLoading,
        navigate,
        dispatch,
        t
      })

      // navigate("/manage/generated-campaign-imaage-preview", {
      //   state: {
      //     selectedChannels: selectedChannels,
      //     campaignName: campaignName,
      //     adRedirectUrl: modifiedeRedirectUrl,
      //     multiSelectTypes,
      //     selectedPersonas,
      //     selectedImageNew: modifiedImage ? modifiedImage : selectedImage,
      //     isCampaignForMobile,
      //     position: isCampaignForMobile ? mobilePosition: position
      //   }
      // });
      // Toastr.success(t("Success"));
      // dispatch(
      //   updateComponent({
      //     component: "Design Preview",
      //     component_label: "Generate Dimensions"
      //   })
      // );
      // dispatch(incrementActions());
    }
  };

  useEffect(()=>{
if(navigationFrom==="designPreview") {
  setLoading(true)
  if(isCampaignForMobile){
    setMobilePosition(designPreviewPosition)
  }
  else {
    setPosition(designPreviewPosition)
  }
  setCampaignName(designPreviewCampaignName)
  setAdRedirectUrl(designPreviewAdRedirectUrl)
  setModifiedImage(selectedImage)
  setLoading(false)
}
  }, [])

  return (
    <>
      <AuthorizedHeader />

      <div className="container image-design-preview-component">
        <div className="generated-designs background-box">
          <div className="generated-designs-margins">
            <div className="flex-between">
              <div className="modules-heading">
                <p className="modules-heading">
                  {t("manageCampaign.nameCampaign")}
                </p>
              </div>
              <div className="close-icon">
                <img
                  src={close}
                  onClick={() => {
                    navigate("/manage/add-campaign")
                  }}
                  role="button"
                  tabIndex={0}
                  onKeyDown={(e) => {
                    // Handling keyboard events
                    if (e.key === "Enter" || e.key === " ") {
                      navigate(-1);
                    }
                  }}
                  v
                  className="close-icon-img"
                />
              </div>
            </div>

            <form onSubmit={handleSubmit}>
              <div className="flex-space">
                {/* Input field */}
                <div className="input-width">
                  {/* Label */}
                  <div className="generated-designs-campaign-name" >
                    {t("manageCampaign.campaignName")}
                  </div>
                  {/* Label ends */}

                  {/* Input field */}
                  <div>
                    <input
                      type="text"
                      className={
                        errors.campaignName
                          ? "input-field-common-error"
                          : "input-field-common input-placeholder"
                      }
                      value={campaignName}
                      onChange={handleInputChange(
                        setCampaignName,
                        "campaignName"
                      )}
                      //   placeholder={placeholder}
                    />
                    {errors.campaignName}
                  </div>

                  {/* Input field ends */}

                  {/* Label */}
                  <div
                    className="generated-designs-campaign-name"
                  >
                    {t("manageCampaign.adUrl")}
                  </div>
                  {/* Label ends */}

                  {/* Input field */}
                  <div>
                    <input
                      type="text"
                      className={
                        errors.adRedirectUrl
                          ? "input-field-common-error"
                          : "input-field-common input-placeholder"
                      }
                      value={adRedirectUrl}
                      // onChange={handleAdRedirectUrlChange}
                      // onChange={handleAdRedirectUrlChange(
                      //   setAdRedirectUrl,
                      //   "adRedirectUrl"
                      // )}
                      onChange={handleInputChange(
                        setAdRedirectUrl,
                        "adRedirectUrl",
                        useSameUrl,
                        setCtaButtonUrl
                      )}

                      //   placeholder={placeholder}
                    />
                    {errors.adRedirectUrl}
                  </div>

                  <div className="generated-designs-campaign-name">
                      {t("manageCampaign.position")}
                    </div>
                  {
                    !isCampaignForMobile ?  <div className="position-dropdowns-container">
                    <DropdownComponent
                      value={position}
                      name={"position"}
                      options={positionOptions}
                      onChange={(e) => {
                        const value = e.target.value;
                        setPosition(value);
                        // handleTranslateText(value);
                        dispatch(
                          updateComponent({
                            component: "position",
                            component_label: value
                          })
                        );
                        dispatch(incrementActions());
                      }}  
                      marginTop="0px"
                      minWidth="200px"
                      defaultValue={"position"}
                    />
                  </div>  :   <div className="position-dropdowns-container">
                      <DropdownComponent
                        value={mobilePosition}
                        name={"mobilePosition"}
                        options={mobilePositionOptions}
                        onChange={(e) => {
                          const value = e.target.value;
                          setMobilePosition(value);
                          // handleTranslateText(value);
                          dispatch(
                            updateComponent({
                              component: "mobilePosition",
                              component_label: value
                            })
                          );
                          dispatch(incrementActions());
                        }}  
                        marginTop="0px"
                        minWidth="200px"
                        defaultValue={"mobile Position"}
                      />
                    </div>   
                  }
                    
                   
                  {/* Input field ends */}

                  {/* Label */}
                  {/* <div
                    style={{
                      paddingTop: "5%"
                    }}
                    className="generated-designs-campaign-name"
                  >
                    {t("manageCampaign.ctaUrl")}
                  </div> */}
                  {/* Label ends */}

                  {/* Input field */}
                  {/* Input field ends */}

                </div>
                {/* Input field ends */}

                {/* Previewed image */}
                {
                  modifiedImage ?                
                   <div className="preview-campaign-background">
                  <div>
                    <div className="preview-campaign-text">
                      {t("manageCampaign.preview")}
                    </div>
                  </div>
                  <div className="preview-image-container">
                  <img 
                src={modifiedImage?.src} 
                alt="Preview" 
                className="preview-image"
              />
                    {/* <ReactJsxParser
                      components={{}}
                      jsx={template}
                      renderInWrapper={false}
                      autoCloseVoidElements
                    /> */}
                  </div>
                  {
                    channelName?.includes('Website')? 
                    loading ? 
                    <div className="preview-loading-container">
                        <Loader  text={t("manageCampaign.generatingImages")} />
                      </div>
                     : <div className="preview-edit-button-container">
                     <ButtonDark
                         buttonText={t("manageCampaign.editImage")}
                         onClick={(e)=>setEditImageModel(true)}
                       />
                     </div>
                    : null
                  }
                </div> : null
                }

              
                {/* Previewed image ends */}
              </div>

              {loading ? (
                <Loader text={t("manageCampaign.completingCampaign")} />
              ) : (
                <div className="flex-center">
                  <div className="cancel-button-padding-right">
                    <ButtonLight
                      buttonText={t("common.confirmation.cancel")}
                      onClick={() => {
                        // navigate(-1);
                        navigate("/manage/add-campaign")
                      }}
                    />
                  </div>
                  <div
                   className="button-container-right-padding"
                  >
                    <ButtonDark
                      isSubmit
                      buttonText={t("common.confirmation.next")}
                    />
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>
        <Dialog
        open={editImageModel}
        onClose={() => {
          setEditImageModel(false);
        }}
        maxWidth={false}
        fullWidth
        className="user-management"
        PaperProps={{
          style: {
            // minHeight: "12vw",
            // width:"90%",
            // padding: "0px"
            height: "90vh",
            width: "95%",
            maxWidth: "1400px",
            padding: "20px",
            display: "flex",
              flexDirection: "column"
          }
        }}
      >
        <div className="add-compaign-edit-image-container">
        <AddCampaignEditImageComponent
          setEditImageModel={setEditImageModel}
          selectedImage={selectedImage}
          websiteID={websiteID}
          setModifiedImage={setModifiedImage}
          setLoading={setLoading}
          Toastr={Toastr}
          loading={loading}
        />
        </div>
       
      </Dialog>
      </div>

    
    </>
  );
}
