import Moment from "moment";
// import { getDatesInRange } from "./dataFilter";
import LocalStorage from "./LocalStorgae";

const languageOrientedSpend = () => {
  const language = LocalStorage.getItem("selectedLanguage");

  let below;
  let above;
  switch (language) {
    case "de":
      below = "Unter";
      above = "Über";
      break;
    case "it":
      below = "Sotto";
      above = "Sopra";
      break;
    case "en":
    default:
      below = "Below";
      above = "Above";
      break;
  }

  return { below, above };
};

export const spendRange = (amount) => {
  const { below, above } = languageOrientedSpend();

  if (amount === 0) return "";
  if (amount < 2000) {
    return `${below} 2000`;
  }
  if (amount >= 2000 && amount < 3000) {
    return "2000 - 3000";
  }
  if (amount >= 3000 && amount < 4000) {
    return "3000 - 4000";
  }
  if (amount >= 4000 && amount < 5000) {
    return "4000 - 5000";
  }
  if (amount >= 5000 && amount < 6000) {
    return "5000 - 6000";
  }
  return `${above} 6000`;
};

export const modifyData = (data) => {
  return data?.map((ele) => {
    const dateUTC = new Date(ele.date);
    const tempDate = `${dateUTC.getDate()}/${dateUTC.getMonth() + 1}/${dateUTC
      .getFullYear()
      .toString()
      .slice(-2)}`;

    return {
      ...ele,
      oldDate: ele.date,
      date: tempDate,
      totalSpend: spendRange(ele.totalSpend)
    };
  });
};

// Function to extract unique keys and sum their values across the array
export function separatePersonaCategory(data) {
  const sums = {}; // To hold sums for unique keys

  data.forEach((item) => {
    const categoryInteraction = item.CategoryInteraction;

    for (const key in categoryInteraction) {
      if (categoryInteraction.hasOwnProperty(key)) {
        // Convert the string value to a number and accumulate the sum
        const value = parseFloat(categoryInteraction[key]);

        if (!sums[key]) {
          sums[key] = 0; // Initialize the sum for new keys
        }

        sums[key] += value; // Add to the sum for the current key
      }
    }
  });

  // Create an array of unique keys and their sums
  const result = [];

  for (const key in sums) {
    if (sums.hasOwnProperty(key)) {
      result.push({ key, total: sums[key] }); // Create an object for each unique key with its total sum
    }
  }

  // Sort the result array based on the total sum
  result.sort((a, b) => b.total - a.total);

  return result; // Return the result as an array of objects
}

const getWeekNumber = (date) => {
  const d = new Date(date);
  if (Number.isNaN(d)) return null; // Return null for invalid dates
  const startOfYear = new Date(d.getFullYear(), 0, 1);
  const millisecondsInWeek = 7 * 24 * 60 * 60 * 1000; // Total milliseconds in a week
  const weekNumber = Math.floor((d - startOfYear) / millisecondsInWeek) + 1;
  return weekNumber <= 52 ? weekNumber : 52; // Ensure week number doesn't exceed 52
};

const getMonthName = (monthIndex) => {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ];
  return monthNames[monthIndex] || null;
};

export const formatDate = (date, format) => {
  const d = new Date(date);
  if (Number.isNaN(d.getTime())) return null; // Return null for invalid dates
  switch (format) {
    case "Weekly": {
      const weekNumber = getWeekNumber(d);
      return weekNumber ? `Week ${weekNumber}` : null; // Handle invalid week number
    }
    case "Monthly": {
      const month = getMonthName(d.getMonth());
      return month ? `${month} ${d.getFullYear()}` : null;
    }
    case "Yearly": {
      const year = d.getFullYear();
      return year ? `${year}` : null;
    }
    case "Daily":
    default:
      return date;
  }
};

// const groupBy = (array, keyFunction) => {
//   return array.reduce((accumulator, currentItem) => {
//     const key = keyFunction(currentItem);
//     if (!key) return accumulator; // Skip invalid keys

//     const newAccumulator = { ...accumulator };
//     if (!newAccumulator[key]) {
//       newAccumulator[key] = [];
//     }
//     newAccumulator[key].push(currentItem);

//     return newAccumulator;
//   }, {});
// };

export const getRecordsByDate = (responseData, startDate, endDate) => {
  const revenue = [];
  const requests = [];
  const bookings = [];
  const labels = [];
  const dataMap = new Map();

  responseData.forEach((record) => {
    const { date, year: fullYear } = record;
    const [day, month] = date.split("/").map(Number);
    const dateName = `${fullYear}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}`;
    if (!dataMap.has(dateName)) {
      dataMap.set(dateName, { revenue: 0, roomReq: 0, roomBook: 0 });
    }
    dataMap.get(dateName).revenue += record.revenue;
    dataMap.get(dateName).roomReq += Number(record.roomReq);
    dataMap.get(dateName).roomBook += Number(record.roomBook);
  });

  const end = new Date(endDate);
  const currentDate = new Date(startDate);
  while (currentDate <= end) {
    const formattedDate = currentDate.toISOString().split("T")[0];
    const dateString = `${currentDate.getDate()} ${currentDate.toLocaleDateString(
      "en-US",
      { month: "short" }
    )}`;
    const data = dataMap.get(formattedDate) || {
      revenue: 0,
      roomReq: 0,
      roomBook: 0
    };
    revenue.push(data.revenue);
    requests.push(data.roomReq);
    bookings.push(data.roomBook);
    labels.push(dateString);
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return { labels, revenue, requests, bookings };
};

export const getRecordsByWeekly = (responseData) => {
  const weeksData = new Map();

  for (let i = 1; i <= 52; i += 1) {
    weeksData.set(i, { revenue: 0, roomReq: 0, roomBook: 0 });
  }

  responseData?.forEach((record) => {
    const { week_number: weekNumber } = record;
    if (!weeksData.has(weekNumber)) {
      weeksData.set(weekNumber, {
        revenue: 0,
        roomReq: 0,
        roomBook: 0
      });
    }
    weeksData.get(weekNumber).revenue += record.revenue;
    weeksData.get(weekNumber).roomReq += Number(record.roomReq);
    weeksData.get(weekNumber).roomBook += Number(record.roomBook);
  });

  const sortedWeeks = Array.from(weeksData.keys()).sort((a, b) => a - b);

  const labels = sortedWeeks.map((weekNumber) => `Week ${weekNumber}`);
  const revenue = [];
  const requests = [];
  const bookings = [];

  sortedWeeks.forEach((weekNumber) => {
    const data = weeksData.get(weekNumber);
    revenue.push(data.revenue);
    requests.push(data.roomReq);
    bookings.push(data.roomBook);
  });
  return { labels, revenue, requests, bookings };
};

export const getRecordsByMonthly = (responseData) => {
  const monthsData = new Map();

  responseData.forEach((record) => {
    const { month, year } = record;
    const monthYear = `${month}-${year}`;
    if (!monthsData.has(monthYear)) {
      monthsData.set(monthYear, { revenue: 0, roomReq: 0, roomBook: 0 });
    }
    monthsData.get(monthYear).revenue += record.revenue;
    monthsData.get(monthYear).roomReq += Number(record.roomReq);
    monthsData.get(monthYear).roomBook += Number(record.roomBook);
  });

  const sortedMonths = Array.from(monthsData.keys()).sort();

  const labels = sortedMonths.map((monthYear) => {
    const [month, year] = monthYear.split("-");
    return `${month} ${year}`;
  });

  const revenue = [];
  const requests = [];
  const bookings = [];

  sortedMonths.forEach((monthYear) => {
    const data = monthsData.get(monthYear);
    revenue.push(data.revenue);
    requests.push(data.roomReq);
    bookings.push(data.roomBook);
  });
  return { labels, revenue, requests, bookings };
};

export const getRecordsByYearly = (responseData) => {
  const yearsData = new Map();

  responseData.forEach((record) => {
    const { year } = record;
    if (!yearsData.has(year)) {
      yearsData.set(year, { revenue: 0, roomReq: 0, roomBook: 0 });
    }
    yearsData.get(year).revenue += record.revenue;
    yearsData.get(year).roomReq += Number(record.roomReq);
    yearsData.get(year).roomBook += Number(record.roomBook);
  });

  const sortedYears = Array.from(yearsData.keys()).sort();

  const labels = sortedYears.map((year) => `${year}`);

  const revenue = [];
  const requests = [];
  const bookings = [];

  sortedYears.forEach((year) => {
    const data = yearsData.get(year);
    revenue.push(data.revenue);
    requests.push(data.roomReq);
    bookings.push(data.roomBook);
  });

  return { labels, revenue, requests, bookings };
};

export const processDataByDate = (dateRange, filterType, responseData) => {
  if (filterType === "Weekly") {
    return getRecordsByWeekly(responseData);
  }
  if (filterType === "Monthly") {
    return getRecordsByMonthly(responseData);
  }
  if (filterType === "Yearly") {
    return getRecordsByYearly(responseData);
  }
  if (filterType === "Daily") {
    return getRecordsByDate(
      responseData,
      Moment(dateRange.startDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
      Moment(dateRange.endDate, "DD-MM-YYYY").format("YYYY-MM-DD")
    );
  }
  return {};
};

export const aggregateDataByCurrentLink = (data) => {
  const result = {};

  data.forEach((item) => {
    const { preSales, sales, revenue, visitors, views, bounce, label } = item;

    if (!result[label]) {
      result[label] = {
        preSalesEvent: 0,
        salesEvent: 0,
        revenue: 0,
        visitors: 0,
        views: 0,
        bounce: 0
      };
    }

    result[label].preSalesEvent += parseInt(preSales, 10);
    result[label].salesEvent += parseInt(sales, 10);
    result[label].revenue += parseFloat(revenue);
    result[label].visitors += parseInt(visitors, 10);
    result[label].views += parseInt(views, 10);
    result[label].bounce += parseInt(bounce, 10);
  });

  // Convert the result object to an array of objects
  const sortedData = Object.keys(result).map((link) => ({
    label: link,
    visitors: result[link].visitors,
    views: result[link].views,
    preSalesEvent: result[link].preSalesEvent,
    salesEvent: result[link].salesEvent,
    revenue: result[link].revenue,
    bounce: result[link].bounce
  }));

  // Sort the data based on visitors
  sortedData.sort((a, b) => b.visitors - a.visitors);

  // Convert the sorted data back to the desired format
  const labels = sortedData.map((item) => item.label);
  const visitors = sortedData.map((item) => item.visitors);
  const views = sortedData.map((item) => item.views);
  const preSalesEvent = sortedData.map((item) => item.preSalesEvent);
  const salesEvent = sortedData.map((item) => item.salesEvent);
  const revenue = sortedData.map((item) => item.revenue);
  const bounce = sortedData.map((item) => item.bounce);

  return {
    labels,
    visitors,
    views,
    preSalesEvent,
    salesEvent,
    revenue,
    bounce
  };
};

export function getKeywords(data, filteredLongtails = []) {
  const keywordCounts = {};
  for (const item of data) {
    if (item.keywords) {
      const visitors = Number(item.visitors) || 0;
      if (filteredLongtails.length > 0 && item.long_tail_keywords) {
        for (const [key, keyword] of Object.entries(item.long_tail_keywords)) {
          if (filteredLongtails.some((filter) => keyword === filter)) {
            keywordCounts[key] = (keywordCounts[key] || 0) + visitors;
          }
        }
      } else {
        item.keywords
          .split(",")
          .map((keyword) => keyword.trim())
          .forEach((keyword) => {
            if (keyword) {
              keywordCounts[keyword] = (keywordCounts[keyword] || 0) + visitors;
            }
          });
      }
    }
  }
  return Object.entries(keywordCounts)
    .map(([label, visitors]) => ({ label, visitors }))
    .sort((a, b) => b.visitors - a.visitors);
}

export function getLongtailKeywords(data, filteredKeys = []) {
  const longtailKeywordCounts = {};
  for (const item of data) {
    if (item.long_tail_keywords) {
      const visitors = Number(item.visitors) || 0;

      if (filteredKeys?.length > 0) {
        for (const [key, keyword] of Object.entries(item.long_tail_keywords)) {
          if (keyword) {
            if (filteredKeys.includes(key)) {
              longtailKeywordCounts[keyword] =
                (longtailKeywordCounts[keyword] || 0) + visitors;
            }
          }
        }
      } else {
        for (const keyword of Object.values(item.long_tail_keywords)) {
          if (keyword) {
            longtailKeywordCounts[keyword] =
              (longtailKeywordCounts[keyword] || 0) + visitors;
          }
        }
      }
    }
  }

  return Object.entries(longtailKeywordCounts)
    .map(([label, visitors]) => ({ label, visitors }))
    .sort((a, b) => b.visitors - a.visitors);
}
