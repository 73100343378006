/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable */
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import ReactJsxParser from "react-jsx-parser";
import domtoimage from "dom-to-image-more";
import close from "../../../../../assets/icons/close.png";
import download from "../../GeneratedCampaign/assets/icons/download.png";
import "../assets/stylesheets/openedWithImageModel.scss";
import {
  incrementActions,
  updateComponent
} from "../../../../../store/slices/trackingSlice";
import { useDispatch } from "react-redux";

export default function OpenedWithImageModel({
  setOpenedImage,
  selectedImage,
  selectedName,
  selectedDimension,
  selectedPosition = "center",
  isCompaignForMobile = 1
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const renderedRef = useRef(null);

  const width = selectedDimension?.split("x")[0]
  const height = selectedDimension?.split("x")[1]



  // const handleDownload = (imageUrl) => {
  //   // const imageUrl = 'https://example.com/path/to/your/image.jpg'; // Replace with your image URL
  //   const link = document.createElement('a');
  //   link.href = imageUrl;
  //   link.download = imageUrl.substring(imageUrl.lastIndexOf('/') + 1); // Extracts the filename from the URL
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // };


  const handleDownload = (imageUrl, format = "png") => {
    fetch(imageUrl)
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        // link.download = `image-${Date.now()}.png`; 
        const name = `downloaded-image.${format}`
        link.download = `downloaded-image.${format}` // Force download with a filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
        dispatch(
          updateComponent({
            component: "Download",
            component_label: `${name} - ${selectedDimension}`
          })
        );
        dispatch(incrementActions());
      })
      .catch(error => console.error('Error downloading image:', error));
  };

  // const handleDownload = async (format = "png") => {
  //   if (renderedRef.current) {
  //     try {
  //       const dataUrl =
  //         format === "png"
  //           ? await domtoimage.toPng(renderedRef.current, {
  //               width: renderedRef.current.offsetWidth,
  //               height: renderedRef.current.offsetHeight,
  //               quality: 1
  //             })
  //           : await domtoimage.toJpeg(renderedRef.current, {
  //               width: renderedRef.current.offsetWidth,
  //               height: renderedRef.current.offsetHeight,
  //               quality: 1
  //             });

  //       // Create a temporary download link
  //       const link = document.createElement("a");
  //       link.href = dataUrl;
  //       link.download = `downloaded-image.${format}`;
  //       link.click();
  //       dispatch(
  //         updateComponent({
  //           component: "Download",
  //           component_label: `${selectedName} - ${selectedDimension}`
  //         })
  //       );
  //       dispatch(incrementActions());
  //     } catch (error) {
  //       console.error("Error capturing image:", error);
  //     }
  //   }
  // };

  return (
    <div className="opened-with-image-model">
      <div className="flex-space preview-close">
        <div className="preview-text">{t("manageCampaign.preview")}</div>
        <div
          onClick={() => {
            setOpenedImage(false);
          }}
          role="button"
          tabIndex={0}
          onKeyDown={(e) => {
            // Handling keyboard events
            if (e.key === "Enter" || e.key === " ") {
              setOpenedImage(false);
            }
          }}
        >
          <img src={close} className="close-img" alt="close" />
        </div>
      </div>

{
  isCompaignForMobile ?  <div
  ref={renderedRef}
  className={`image-section-mobile-main-div  position-${selectedPosition}`}      >
  <img
    src={selectedImage}
    alt="preview"
    className="img-mobile-style"
  />
</div> :  
 <div
  ref={renderedRef}
  className={`image-section-main-div position-${selectedPosition}`}      >
  <img
    src={selectedImage}
    alt="preview"
    className={`img-dekstop-style ${
      width < 1000 ? 'full-width' : 'half-width'
    } ${height < 550 ? 'full-height' : 'half-height'}`}
    // style={{
    //   maxWidth: width< 1100 ? "100%": "45%",
    //   maxHeight: height< 550 ? "70vh": "45%",
    // }}
  />
</div>
}
    
      {/* Image section ends */}

      {/* Image name, dimensions and download icon */}
      <div className="name-dimensions">
        <div className="flex-space">
          {/* <div className="image-name">{selectedName}</div> */}
        </div>
        <div className="dimensions">{selectedDimension}</div>

        <div
          // style={{
          //   marginTop: "15px",
          //   cursor: "pointer"
          // }}
          onClick={() => handleDownload(selectedImage, "png")}
          className="margin-top15"
        >
          <div
            // style={{
            //   paddingRight: "12px"
            // }}
            className="download-text"
          >
            Download
          </div>
          <img
          className="download-image-icon"
            src={download}
          />
        </div>
      </div>
      {/* Image name, dimensions and download icon */}
    </div>
  );
}
