/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ButtonDark,
  ButtonLight
} from "../../../../../components/inputElements/buttons/MainButton";
import collapse from "../../SavedCampaign/assets/icons/collapse.png";
import expand from "../../SavedCampaign/assets/icons/expand.png";
import "../assets/stylesheets/generatedCampaignFinal.scss";
import AuthorizedHeader from "../../../../../components/layouts/AuthorizedHeader";
import { useLocation, useNavigate } from "react-router-dom";
import WebAdvertisement from "../../SavedCampaign/components/WebAdvertisement";
import { Dialog } from "@mui/material";
import OpenedWithImageModel from "../../SavedCampaign/components/OpenedWithImageModel";
import { addCampaignImages } from "../../AddCampaign/api/addCampaignAPI";
import { useDispatch, useSelector } from "react-redux";
import { Toastr } from "../../../../../components/Toastr/Toastr";
import Loader from "../../AddCampaign/components/Loader";
import { handleSaveCampaign } from "../../../services/addCampaignServices";
import GoogleMarketingImages from "../../SavedCampaign/components/GoogleMarketingImages";
import WebImageAdvertisement from "../../SavedCampaign/components/WebImageAdvertisement";

export default function GeneratedCampaignImagePreviewFinal() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const location = useLocation();
  const navigate = useNavigate();
  const { websiteID } = useSelector((state) => state.generalData);
  const {
    selectedChannels,
    campaignName,
    multiSelectTypes,
    selectedPersonas,
    adRedirectUrl,
    selectedImageNew,
    isCampaignForMobile,
    position
  } = location.state || {};

  const [loading, setLoading] = useState(false);

  const [showWebAds, setShowWebAds] = useState(true);
  const [showGoogleAds, setShowGoogleAds] = useState(true);
  const [openedImage, setOpenedImage] = useState(false);
  // const [selectedImage, setSelectedImage] = useState(null);

  const [campaignBanners, setCampaignBanners] = useState([]);

  const [campaignInfo, setCampaignInfo] = useState([]);
  const [selectedName, setSelectedName] = useState("");
  const [selectedDimension, setSelectedDimension] = useState("");

  const [selectedImage, setSelectedImage] = useState(selectedImageNew)

  // const encodedAds = multiDimensionAds?.website?.map((ad) => ({
  //   imageLink: selectedImageNew
  //     ? selectedImageNew?.src
  //     : selectedRandomObject?.imageUrl,
  //   name: ad?.name,
  //   dimensions: ad?.dimensions,
  //   template: btoa(ad?.template)
  // }));

  // const encodedAds = Object.keys(multiDimensionAds || {}).flatMap((channel) =>
  //   multiDimensionAds[channel]?.map((ad) => ({
  //     imageLink: selectedImageNew
  //       ? selectedImageNew?.src
  //       : selectedRandomObject?.imageUrl,
  //     name: ad?.name,
  //     dimensions: ad?.dimensions,
  //     template: btoa(ad?.template)
  //   }))
  // );

  // Function to dynamically encode all templates in an ads structur

  const handleAddImagePopUpOpen = () => {
    setAddImagePopUp(true);
    dispatch(
      updateComponent({
        component: "Upload Images",
        component_label: "Add Image"
      })
    );
    dispatch(incrementActions());
  };



  let websiteAds = selectedChannels?.some(
    (channel) => channel?.channel_id === 6
  );

  let googleAds = selectedChannels?.some(
    (channel) => channel?.channel_id === 1
  );

  const saveCampaign = async () => {
    const newCampaigns = selectedChannels.map((channel) => {
      // Extract type_ids from the channel's types
      const channelTypeIds = channel.type.map((type) => type.type_id);

      // Filter the types based on multiSelectTypes for the current channel
      const relevantTypes = multiSelectTypes.filter((type) =>
        channelTypeIds.includes(type.type_id)
      );

      let channelName = channel.channelName?.toLowerCase();

      return {
        website_id: websiteID,
        channel_id: channel.channel_id,
        type_id: relevantTypes.map((type) => type.type_id),
        campaign_name: campaignName,
        redirect_url: adRedirectUrl,
        // cta_url: "",
        persona: selectedPersonas[0],
        // advertisment_image: encodedAds.map((tmpl) => ({
        //   imageLink: selectedRandomObject.imageUrl,
        //   template: tmpl
        // })),
        advertisment_image: selectedImageNew?.src || "", // Fallback to an empty array if no ads for the channel        title: selectedImageNew ? heading : selectedRandomObject?.title,
        // title: "",
        // content: "",
        // cta: "",
        is_campaign_for_mobile: isCampaignForMobile || 0,
        position:position,
        dimensions: selectedDimension
      };
    });
    await handleSaveCampaign(newCampaigns, setLoading, navigate, dispatch); // Call the reusable function
  };

  return (
    <>
      <AuthorizedHeader />

      <div className="container">
        <div
          className="generated-designs background-box"
        >
          <div className="generated-designs-margins">
            <div className="flex-column-height">
              <div>
                <div className="modules-heading flex-between">
                  <p className="modules-heading">{campaignName}</p>
                  {/* <div
                    className="bottom-margin"
                    style={{
                      marginRight: "10px"
                    }}
                  >
                    <ButtonDark
                      buttonText={t("ManageApi.back")}
                      onClick={() => {
                        navigate(-1);
                      }}
                    />
                  </div> */}
                </div>

                {/* <div className="images-text">
                  <p>Google images</p>gen
                </div>

                <div className="images-text">
                  <p>Facebook images</p>
                </div> */}

                {/* Ads scroll container */}
                <div className="scroll-container">
                  {/* Web Ads */}
                  {websiteAds && (
                    <div
                      className="flex-pointer"
                      onClick={() => {
                        setShowWebAds(!showWebAds);
                      }}
                      role="button"
                      tabIndex={0}
                      onKeyDown={(e) => {
                        // Handling keyboard events
                        if (e.key === "Enter" || e.key === " ") {
                          setShowWebAds(!showWebAds);
                        }
                      }}
                    >
                      <div className="images-text">
                        {isCampaignForMobile ? t("manageCampaign.webAdvertisementForMobile") : t("manageCampaign.webAdvertisement") }
                      </div>

                      {showWebAds ? (
                        <img src={expand} className="expand-collapse-icons" />
                      ) : (
                        <img src={collapse} className="expand-collapse-icons" />
                      )}
                    </div>
                  )}

                  {websiteAds && showWebAds ? (
                    <div>
                      <WebImageAdvertisement
                        webImages={selectedImage}
                        setOpenedImage={setOpenedImage}
                        setSelectedName={setSelectedName}
                        setSelectedDimension={setSelectedDimension}
                        navigationFrom="createFlow"
                        selectedImage={selectedImage}
                        setSelectedImage={setSelectedImage}
                      />
                    </div>
                  ) : null}
                  {/* Web Ads end */}

                  {/* Google ads */}

                  {/* Google ads end */}

                  {/* Facebook and insta ads */}
                  {/* <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      cursor: "pointer"
                    }}
                    onClick={() => {
                      setShowFacebookAds(!showFacebookAds);
                    }}
                  >
                    <div
                      style={{
                        paddingTop: "20px"
                      }}
                      className="images-text"
                    >
                      Facebook marketing images
                    </div>

                    {showFacebookAds ? (
                      <img
                        src={expand}
                        style={{
                          width: "24px",
                          height: "24px"
                        }}
                      />
                    ) : (
                      <img
                        src={collapse}
                        style={{
                          width: "24px",
                          height: "24px"
                        }}
                      />
                    )}
                  </div> */}

                  {/* {showFacebookAds ? (
                    <div>
                      <FacebookAndInstaAds
                        setOpenedImage={setOpenedImage}
                        setSelectedImage={setSelectedImage}
                      />
                    </div>
                  ) : null} */}
                  {/* Facebook and insta ads end */}
                </div>
                {/* Ads scroll container ends */}
              </div>

              {loading ? (
                <Loader text={t("manageCampaign.savingCampaign")} />
              ) : (
                <div className="flex-center buttons-top">
                  <div className="cancel-button-padding-right">
                    <ButtonLight
                      buttonText={t("ManageApi.back")}
                      onClick={() => {
                        navigate("/manage/design-image-preview", {
                          state: {
                            selectedChannels,
                            designPreviewCampaignName: campaignName,
                            multiSelectTypes,
                            selectedPersonas,
                            designPreviewAdRedirectUrl:adRedirectUrl,
                            selectedImage: selectedImageNew,
                            isCampaignForMobile,
                            designPreviewPosition:position,
                            navigationFrom:"designPreview"
                          }
                        });
                      }}
                    />
                  </div>
                  <ButtonDark
                    buttonText={t("manageCampaign.saveCampaign")}
                    onClick={saveCampaign}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <Dialog
        open={openedImage}
        onClose={() => {
          setOpenedImage(false);
          setSelectedImage(null);
        }}
        maxWidth="lg"
        fullWidth
        className="user-management"
        PaperProps={{
          style: {
            minHeight: "12vw",
            padding: "0px"
          }
        }}
      >
        <OpenedWithImageModel
          selectedImage={selectedImage?.src}
          setOpenedImage={setOpenedImage}
          selectedName={selectedName}
          selectedDimension={selectedDimension}
          selectedPosition={position}
          isCompaignForMobile={isCampaignForMobile}
        />
      </Dialog>
    </>
  );
}
