/* eslint-disable */
import React, { useEffect, useState } from "react";
import { ButtonDark } from "../../../../../components/inputElements/buttons/MainButton";
import "../../GeneratedCampaign/assets/stylesheets/generatedCampaignFinal.scss";
import "../assets/stylesheets/savedImageCampaign.scss";
import themeColors from "../../../../../assets/stylesheets/_var.scss";
import JSZip from "jszip";
import domtoimage from "dom-to-image-more";
import collapse from "../assets/icons/collapse.png";
import expand from "../assets/icons/expand.png";
import { isMobile } from "react-device-detect";
import CampaignTile from "./CampaignTile";
import WebAdvertisement from "./WebAdvertisement";
import GoogleMarketingImages from "./GoogleMarketingImages";
import { Dialog } from "@mui/material";
import OpenedWithImageModel from "./OpenedWithImageModel";
import FacebookAndInstaAds from "./FacebookAndInstaAds";
import AuthorizedHeader from "../../../../../components/layouts/AuthorizedHeader";
import { useLocation, useNavigate } from "react-router-dom";
import DeleteComponent from "../../../../../components/DeletePopUp/DeleteComponent";
import { useDispatch, useSelector } from "react-redux";
import {
  changeCampaignStatus,
  deleteCampaigns
} from "../../AddCampaign/api/addCampaignAPI";
import Toastr from "../../../../../components/Toastr/Toastr";
import ToggleSwitch from "../../../../../components/ToggleSwitch/ToggleSwitch";
import { TailSpin } from "react-loader-spinner";
import { useTranslation } from "react-i18next";
import { useRef } from "react";
import Loader from "../../AddCampaign/components/Loader";
import {
  incrementActions,
  updateComponent
} from "../../../../../store/slices/trackingSlice";
import WebImageAdvertisement from "./WebImageAdvertisement";


export default function SavedCampaign() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false); // Loading state

  const location = useLocation();
  const { websiteID } = useSelector((state) => state.generalData);

  const { selectedCampaign } = location.state || {};

  const [campaignBanners, setCampaignBanners] = useState([]);

  const [downloadInProgress, setDownloadInProgress] = useState(false);

  const [isHovered, setIsHovered] = useState(false);
  const [showWebAds, setShowWebAds] = useState(true);
  const [showGoogleAds, setShowGoogleAds] = useState(true);

  const [openedImage, setOpenedImage] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const [selectedDimension, setSelectedDimension] = useState("");

  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [changeCampaignLoading, setChangeCampaignLoading] = useState(false);

  const [campaignEnabled, setCampaignEnabled] = useState(false);

  const multiDimensionAds = selectedCampaign?.advertisment_image

  const templateRefs = useRef([]);
  const selectedTemplates = useRef([]); // To store the indices of selected templates

  const onMouseEnter = () => setIsHovered(true);
  const onMouseLeave = () => setIsHovered(false);

  // Function to remove <a> tags
  const removeAttributesFromATags = (webTemplates) => {
    return webTemplates?.map((template) =>
      template.replace(/<a\b[^>]*>/gi, (match) => {
        // Remove href, target, and rel attributes by replacing them with an empty string
        return match
          .replace(/\s*href="[^"]*"/gi, "") // Removes the href attribute
          .replace(/\s*target="[^"]*"/gi, "") // Removes the target attribute
          .replace(/\s*rel="[^"]*"/gi, ""); // Removes the rel attribute
      })
    );
  };

  // Handler for downloading all images
  // const handleDownloadAll = async (format = "png", channelName) => {
  //   setDownloadInProgress(true); // Start the download process
  //   setIsLoading(true); // Start loading

  //   // Temporarily hide checkboxes
  //   const checkboxes = document.querySelectorAll("input[type='checkbox']");
  //   checkboxes.forEach((checkbox) => (checkbox.style.display = "none"));

  //   const zip = new JSZip(); // Create a new ZIP file instance

  //   const downloadPromises = templateRefs.current.map(
  //     async (template, index) => {
  //       if (template) {
  //         try {
  //           // Capture the image as data URL (PNG or JPEG)
  //           const dataUrl =
  //             format === "png"
  //               ? await domtoimage.toPng(template)
  //               : await domtoimage.toJpeg(template, { quality: 1 });

  //           // Add the image data to the ZIP file
  //           const fileName = `downloaded-image-${index + 1}.${format}`;
  //           zip.file(fileName, dataUrl.split(",")[1], { base64: true }); // Base64 encoding for the image
  //           dispatch(
  //             updateComponent({
  //               component: "Saved Campaign",
  //               component_label: "Download all"
  //             })
  //           );
  //           dispatch(incrementActions());
  //         } catch (error) {
  //           console.error(
  //             `Error capturing image for template ${index + 1}:`,
  //             error
  //           );
  //         }
  //       }
  //     }
  //   );


  //   // Wait for all download promises to resolve
  //   await Promise.all(downloadPromises);

  //   // Generate the ZIP file and trigger the download
  //   zip.generateAsync({ type: "blob" }).then(function (content) {
  //     // Create an Object URL for the blob content
  //     const link = document.createElement("a");
  //     link.href = URL.createObjectURL(content);
  //     link.download = `${channelName}-images.zip`; // Set the name for the ZIP file

  //     // Trigger the download
  //     link.click();
  //     setDownloadInProgress(false); // Stop the download process
  //     setIsLoading(false); // Stop loading once all downloads are done
  //   });

  //   // Restore checkboxes after download
  //   checkboxes.forEach((checkbox) => (checkbox.style.display = "block"));
  // };

  const handleDownloadAll = (imageUrl, format = "png") => {
    fetch(imageUrl)
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        // link.download = `image-${Date.now()}.png`; 
        link.download = `downloaded-image.${format}` // Force download with a filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
        dispatch(
          updateComponent({
            component: "Saved Campaign",
            component_label: "Download all"
          })
        );
        dispatch(incrementActions());
      })
      .catch(error => console.error('Error downloading image:', error));
  };

  const handleDeleteCampaigns = async () => {
    setDeleteLoading(true);
    const deleteCampaignsList = [
      {
        campaign_name: selectedCampaign?.campaign_name,
        channel_id: selectedCampaign?.channel_id
      }
    ]
    try {
      const payload = {
        website_id: websiteID,
        campaignData: deleteCampaignsList
      };
      const res = await deleteCampaigns(payload);
      if (res?.data?.success) {
        Toastr.success(res?.data?.message);
        setShowDeletePopup(false);
        navigate(-1);
        dispatch(
          updateComponent({
            component: "Saved Campaign",
            component_label: `Delete Campaign - ${selectedCampaign?.campaign_name}`
          })
        );
        dispatch(incrementActions());
      }
    } catch (error) {
      setDeleteLoading(false);
      Toastr.error("Failed to delete");
    } finally {
      setDeleteLoading(false);
    }
  };

  const handleChangeCampaign = async () => {
    setCampaignEnabled(!campaignEnabled);
    setChangeCampaignLoading(true);
    try {
      const payload = {
        campaign_status: !campaignEnabled ? 1 : 0,
        channel_id: selectedCampaign?.channel_id,
        website_id: websiteID,
        campaign_name: selectedCampaign?.campaign_name
      };

      const res = await changeCampaignStatus(payload);
      if (res?.data?.campaignsData?.success) {
        Toastr.success(res?.data?.campaignsData?.message);
        dispatch(
          updateComponent({
            component: "Saved Campaign - Enable Campaign",
            component_label:
              payload?.campaign_status === 1 ? "Enable" : "Disable"
          })
        );
        dispatch(incrementActions());
      }
    } catch (error) {
      setChangeCampaignLoading(false);
      Toastr.error("Failed to delete");
    } finally {
      setChangeCampaignLoading(false);
    }
  };

//   useEffect(() => {
//     // const updatedTemplates = removeAttributesFromATags(multiDimensionAds);
//     setCampaignBanners(multiDimensionAds);
//   }, []);

//   useEffect(() => {
//     setDeleteCampaignsList({
//       campaign_name: selectedCampaign?.campaign_name,
//       channel_id: selectedCampaign?.channel_id
//     });
//   }, [selectedCampaign]);

  useEffect(() => {
    if (selectedCampaign?.campaign_status === 1) {
      setCampaignEnabled((state) => true);
    } else {
      setCampaignEnabled((state) => false);
    }
  }, [selectedCampaign]);

  return (
    <>
      <AuthorizedHeader />
      <div className="container saved-image-campaign">
        <div className="generated-designs background-box">
          <div className="main-page-margins">
            <div className="flex-column-height">
              <div className="saved-campaign">
                <div className="flex-between">
                  <div>
                    <p className="modules-heading">
                      {selectedCampaign?.campaign_name}
                    </p>
                  </div>
                  <div
                    className="flex top-margin"
                  >
                    <button
                      style={
                        !isMobile
                          ? { backgroundSize: "50px" }
                          : { backgroundSize: "40px" }
                      }
                      className="delete-multiple"
                      type="button"
                      onClick={() => {
                        setShowDeletePopup(true);
                      }}
                    />
                    <div className="bottom-margin">
                      <ButtonDark
                        buttonText={t("ManageApi.back")}
                        onClick={() => {
                          navigate(-1);
                        }}
                      />
                    </div>
                  </div>

                  {showDeletePopup && (
                    <DeleteComponent
                      onCancel={() => setShowDeletePopup(false)}
                      loading={deleteLoading}
                      onConfirmDelete={() => {
                        handleDeleteCampaigns();
                      }}
                    />
                  )}
                </div>

                <div className="bottom-tile-margin">
                  <CampaignTile
                    isHovered={isHovered}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    selectedCampaign={selectedCampaign}
                  />
                  <div
                    className="padding-top-2"
                  >
                    {changeCampaignLoading ? (
                      <TailSpin
                        height="30"
                        width="30"
                        color={themeColors.primaryColor}
                        ariaLabel="tail-spin-loading"
                        radius=".25"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible
                      />
                    ) : (
                      <ToggleSwitch
                        isToggled={campaignEnabled}
                        setIsToggled={setCampaignEnabled}
                        label={t("manageCampaign.enableCampaign")}
                        onPress={handleChangeCampaign}
                      />
                    )}
                  </div>
                  {/* Ads scroll container */}
                  <div
                  // className="scroll-container"
                  >
                    {selectedCampaign?.channel_id === 6 && (
                      <>
                        <div
                          className="flex-pointer"
                          role="button"
                          tabIndex={0}
                        >
                          <div
                            className="images-text padding-top-20"
                          >
                        {selectedCampaign?.type_id[0]=== 21 ?  t("manageCampaign.webAdvertisementForMobile"): t("manageCampaign.webAdvertisement")}
                        </div>

                          {isLoading ? (
                            <Loader text={t("manageCampaign.downloadingAll")} />
                          ) : (
                            <div
                              className="text-underlined"
                              onClick={() =>
                                handleDownloadAll(selectedImage,"png", "Web ads")
                              }
                            >
                              {t("manageCampaign.downloadAll")}
                            </div>
                          )}
                        </div>

                        {showWebAds ? (
                          <div>
                            {/* <WebAdvertisement
                              webImages={campaignBanners}
                              orginalTemplate={multiDimensionAds}
                              setOpenedImage={setOpenedImage}
                              setSelectedImage={setSelectedImage}
                              setSelectedName={setSelectedName}
                              setSelectedDimension={setSelectedDimension}
                              selectedCampaign={selectedCampaign}
                              navigationFrom="save"
                              templateRefs={templateRefs}
                            /> */}
                            <WebImageAdvertisement 
                            webImages={selectedCampaign?.advertisment_image}
                            orginalTemplate={multiDimensionAds}
                            setOpenedImage={setOpenedImage}
                            setSelectedImage={setSelectedImage}
                            // setSelectedName={setSelectedName}
                            setSelectedDimension={setSelectedDimension}
                            selectedCampaign={selectedCampaign}
                            navigationFrom="save"
                            templateRefs={templateRefs} />
                          </div>
                        ) : null}
                      </>
                    )}

                    {/* Google ads */}
                    {/* {selectedCampaign?.channel_id === 1 && (
                      <>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center"
                          }}
                        >
                          <div
                            style={{
                              paddingTop: "20px"
                            }}
                            className="images-text"
                          >
                            {t("manageCampaign.googleAds")}
                          </div>
                          {isLoading ? (
                            <Loader text={t("manageCampaign.downloadingAll")} />
                          ) : (
                            <div
                              style={{
                                textDecoration: "underline",
                                cursor: "pointer"
                              }}
                              onClick={() =>
                                handleDownloadAll("png", "Google ads")
                              }
                            >
                              {t("manageCampaign.downloadAll")}
                            </div>
                          )}
                        </div>

                        {showGoogleAds ? (
                          <div>
                            <GoogleMarketingImages
                              orginalTemplateCreate={multiDimensionAds}
                              orginalTemplate={multiDimensionAds}
                              googleImages={campaignBanners}
                              setOpenedImage={setOpenedImage}
                              setSelectedImage={setSelectedImage}
                              setSelectedName={setSelectedName}
                              setSelectedDimension={setSelectedDimension}
                              selectedCampaign={selectedCampaign}
                              navigationFrom="save"
                              templateRefs={templateRefs}
                            />
                          </div>
                        ) : null}
                      </>
                    )} */}
                    {/* Google ads end */}

                    {/* Facebook and insta ads */}
                    {/* <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        cursor: "pointer"
                      }}
                      onClick={() => {
                        setShowFacebookAds(!showFacebookAds);
                      }}
                    >
                      <div
                        style={{
                          paddingTop: "20px"
                        }}
                        className="images-text"
                      >
                        Facebook marketing images
                      </div>

                      {showFacebookAds ? (
                        <img
                          src={expand}
                          style={{
                            width: "24px",
                            height: "24px"
                          }}
                        />
                      ) : (
                        <img
                          src={collapse}
                          style={{
                            width: "24px",
                            height: "24px"
                          }}
                        />
                      )}
                    </div> */}

                    {/* {showFacebookAds ? (
                      <div>
                        <FacebookAndInstaAds
                          setOpenedImage={setOpenedImage}
                          setSelectedImage={setSelectedImage}
                        />
                      </div>
                    ) : null} */}
                    {/* Facebook and insta ads end */}
                  </div>
                  {/* Ads scroll container ends */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <Dialog
          open={openedImage}
          onClose={() => {
            setOpenedImage(false);
            setSelectedImage(null);
          }}
          maxWidth="lg"
          fullWidth
          className="user-management"
          PaperProps={{
            style: {
              minHeight: "12vw",
              padding: "0px"
            }
          }}
        >
          <OpenedWithImageModel
            selectedImage={selectedImage}
            setOpenedImage={setOpenedImage}
            // selectedName={selectedName}
            selectedDimension={selectedDimension}
            selectedPosition={selectedCampaign?.position}
            isCompaignForMobile={selectedCampaign?.type_id[0]===21 ? 1: 0}
          />
        </Dialog>
      </div>
    </>
  );
}
