/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TailSpin } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import close from "../../../../../assets/icons/close.png";
import {
  ButtonDark,
  ButtonLight
} from "../../../../../components/inputElements/buttons/MainButton";
import { getAllImages } from "../../../../../pages/apis/uploadImagesAPI";
import "../assets/stylesheets/uploadImagesModel.scss";
import themeColors from "../../../../../assets/stylesheets/_var.scss";
import UploadImageComponent from "./UploadImageComponent";
import Loader from "./Loader";
import { getImageSize } from 'react-image-size';
import { Dialog } from "@mui/material";
import AddImageModel from "../../../../../pages/Components/AddImageModel";
import { handleAllFileUpload, handleUploadAllImages } from "../../../../../pages/Components/uploadImagesServices";
import { updateComponent, incrementActions } from "../../../../../store/slices/trackingSlice";

const mobileMaxHeight = 600;
const mobileMinHeight = 50;
const mobileMinWidth = 50;
const mobileMaxWidth = 380;

const dekstopMiniumWidth = 150
const dekstopMiniumHeight = 90

export default function UploadImagesModel({
  setShowUploadImagesModel,
  handleNaviagetToEdit,
  selectedImage,
  setSelectedImage,
  getUploadImagesLoading,
  isCampaignForMobile
}) {

  const { t } = useTranslation();

  const { websiteID } = useSelector((state) => state.generalData);

  const [allImages, setAllImages] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const [loader, setLoader] = useState(true);
  const [addImagePopUp, setAddImagePopUp] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uploadedImageDetails, setUploadedImageDetails] = useState([]);
  const [uploadedImages, setUploadedImages] = useState([]);

  const dispatch = useDispatch();

  const handleDragOver = (event) => {
    event?.preventDefault();
  };

  const handleClick = () => {
    document?.getElementById("fileInput")?.click();
  };

  const formatFileSize = (sizeInBytes) => {
    return sizeInBytes / (1024 * 1024);
  };
  
  const handleFileUpload = (files) => {
    handleAllFileUpload({
      files,
      formatFileSize,
      setUploadedImageDetails,
      setUploadedImages
    });
  };

  const handleDrop = (event) => {
    event?.preventDefault();
    handleFileUpload(event?.dataTransfer?.files);
  };

  const handleAddImagePopUpOpen = () => {
    setAddImagePopUp(true);
    dispatch(
      updateComponent({
        component: "Upload Images",
        component_label: "Add Image"
      })
    );
    dispatch(incrementActions());
  };

  const handleAddImagePopUpClose = () => {
    setUploadedImages([]);
    setUploadedImageDetails([]);
    setAddImagePopUp(false);
    setAddImagePopUp(false);
  };

  const handleUploadImages = async () => {
    handleUploadAllImages({
      uploadedImageDetails,
      setLoading,
      websiteID,
      handleAddImagePopUpClose,
      getImages,
      t,
      dispatch
    });
  };


  async function checkImageSize(url, isCampaignForMobile) {
    try {
        const dimensions = await getImageSize(url);
        console.log("dimensions", dimensions);
        if(isCampaignForMobile) {
          if( dimensions.width >= mobileMinWidth &&  dimensions.width <=mobileMaxWidth 
            && dimensions.height>= mobileMinHeight && dimensions.height <= mobileMaxHeight){
              return true
            }
            return false
        }
        else {
          if(dimensions.width>=dekstopMiniumWidth && dimensions.height>=dekstopMiniumHeight) {
            return true
          }
          return false
        }
        
    } catch (error) {
        console.error(error);
    }
}
 
  // Function to filter images by size
  const filterImagesBySize = async (allImages, isCampaignForMobile) => {
    const validImages = [];
    for (const imageObj of allImages) {
      try {
        const isValid = await checkImageSize(imageObj?.image, isCampaignForMobile);
        if (isValid) {
          validImages.push(imageObj);
        }
      } catch (error) {
        console.error(error);
      }
    }
    setAllImages(validImages);
    setLoader(false);
  };

  const getImages = async () => {
    setLoader(true);
    try {
      const res = await getAllImages(websiteID);
      console.log("res====>", res);
      const allImages =
        res?.data?.imageUrl?.length > 0 ? res?.data?.imageUrl : [];
      if(allImages.length> 0) {
        await filterImagesBySize(allImages, isCampaignForMobile)
      }
      else {
        setAllImages([]);
      }
      setLoader(false);
    } catch (error) {
      setLoader(true);
      console.error("Error fetching images:", error);
    }
  };

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  useEffect(() => {
    getImages();
  }, []);


  console.log("selectedImage", selectedImage);

  return (
    <div className="upload-images-model">
      <div className="flex-between">
        <div>
          <p className="upload-images-text">{t("uploadImages.uploadImages")}</p>
        </div>

        <div
          className="close-img-div"
          onClick={() => {
            setShowUploadImagesModel(false);
          }}
          role="button"
          tabIndex={0}
          onKeyDown={(e) => {
            // Handling keyboard events
            if (e.key === "Enter" || e.key === " ") {
              setShowUploadImagesModel(false);
            }
          }}
        >
          <img src={close} className="close-img" />
        </div>
      </div>

      <div className="select-image-container">
        <p className="select-image">{t("uploadImages.selectImage")}</p>
        <div className="add-image-button-container">
      <ButtonDark
                buttonText={t("uploadImages.addImages")}
                onClick={handleAddImagePopUpOpen}
              />
      </div>
      </div>
     


      <div>
        {loader ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              //   paddingTop: "15%",
              paddingBottom: "15%"
            }}
          >
            <TailSpin
              height="50"
              width="50"
              color={themeColors.primaryColor}
              ariaLabel="tail-spin-loading"
              radius="2"
              wrapperStyle={{}}
              wrapperClass="spinner"
              visible
            />
          </div>
        ) : (
          allImages.length >0 && 
          <UploadImageComponent
            allImages={allImages}
            photos={photos}
            setPhotos={setPhotos}
            selectedImage={selectedImage}
            setSelectedImage={setSelectedImage}
            hoveredIndex={hoveredIndex}
            handleMouseEnter={handleMouseEnter}
            handleMouseLeave={handleMouseLeave}
          />
        )}
      </div>

      {getUploadImagesLoading ? (
        <div
          style={{
            paddingTop: "5%",
            paddingBottom: "5%"
          }}
        >
          <Loader text={t("manageCampaign.generatingImages")} />
        </div>
      ) : (
        <div className="flex-center cancel-proceed-buttons">
          <div className="cancel-right">
            <ButtonLight
              buttonText={t("common.confirmation.cancel")}
              onClick={() => {
                setShowUploadImagesModel(false);
              }}
            />
          </div>
          <ButtonDark
            disabled={selectedImage.length === 0}
            buttonText={t("common.confirmation.next")}
            onClick={handleNaviagetToEdit}
          />
        </div>
      )}
      <Dialog
          open={addImagePopUp}
          onClose={handleAddImagePopUpClose}
          maxWidth="md"
          fullWidth
          className="user-management"
        >
          <AddImageModel
            loading={loading}
            close={close}
            handleAddImagePopUpClose={handleAddImagePopUpClose}
            uploadedImages={uploadedImageDetails}
            setUploadedImages={setUploadedImageDetails}
            handleClick={handleClick}
            handleDrop={handleDrop}
            handleDragOver={handleDragOver}
            handleFileUpload={handleFileUpload}
            handleUploadImages={handleUploadImages}
          />
        </Dialog> 
    </div>
  );
}

