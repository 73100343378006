import PropTypes from "prop-types";
import "../assets/stylesheets/popup-window.scss";
import { Pagination } from "@mui/material";
import themeColors from "../../../assets/stylesheets/_var.scss";

export default function PopUpWindow({
  className,
  children,
  handlePagination,
  pageNumber,
  numberOfPages
}) {
  return (
    <section className={`popup-modal ${className}`}>
      <div>
        {children}
        {numberOfPages !== 1 && className === "bargraph" && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              margin: "20px 0px"
            }}
          >
            <Pagination
              count={numberOfPages}
              variant="outlined"
              shape="rounded"
              page={pageNumber}
              sx={{
                "& .MuiPaginationItem-root": {
                  backgroundColor: themeColors.background1,
                  color: "#181818",
                  fontSize: "14px",
                  borderColor: themeColors.tertiaryText,
                  "&.Mui-selected": {
                    backgroundColor: themeColors.primaryColor4,
                    color: "#181818"
                  }
                }
              }}
              onChange={(e, page) => {
                handlePagination(e, page);
              }}
            />
          </div>
        )}
      </div>
    </section>
  );
}

PopUpWindow.propTypes = {
  children: PropTypes.element,
  className: PropTypes.string,
  pageNumber: PropTypes.number,
  handlePagination: PropTypes.func.isRequired,
  numberOfPages: PropTypes.number
};
