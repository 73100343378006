/* eslint-disable */
/* eslint-disable */
import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import AuthorizedHeader from "../../components/layouts/AuthorizedHeader";
import DateCalendar from "../../components/DateRange";
import FilterPill from "../../components/FilterPill";
import { getAnalyticsPages, getPersonasData } from "../../store/apiSlice";
import {
  getGraphDataSorted,
  dataFilterFunction,
  getViewsVisitorsData,
  filterAccordingToSelected,
  getHeatmap1Data,
  getHeatmap2Data,
  getHeatmap3Data
} from "../../utils/dataFilter";
import {
  processDataByDate,
  formatDate,
  separatePersonaCategory,
  aggregateDataByCurrentLink,
  getKeywords,
  getLongtailKeywords
} from "../../utils/dataPersonaFilter";
import {
  GraphData,
  filterAccordingToSelected as filterAccordingToPmsSelected
} from "../../utils/dataPMSFilter";
import {
  updatePersonaData,
  updateCountriesData,
  updateWorldData,
  updateViewsVisitorsData,
  updateFilteredData,
  updateCategoriesData,
  updateAgeData,
  updateGenderData,
  updateCitiesData,
  updateTotalSpendData,
  updateVisitorsLineData,
  updateRoomsData,
  updatePageUrlData,
  updateSourceData,
  updateMediumData,
  updateCampaignData,
  updateTermData,
  updateContentData,
  updateHeatmap1Data,
  updateHeatmap2Data,
  updateHeatmap3Data,
  updateKeywordsData,
  updateLongTailKeyWordsData,
  updatePagesAndKewordsFilteredData,
  updateFetchFilterData
} from "../../store/slices/personasSlice";
import { updateSelectedPersonasFilters } from "../../store/slices/generalSlice";
import GenaralDashboard from "../../features/Personas/GeneralPersonaDashboard";
import storage from "../../utils/storage";
import LocalStorage from "../../utils/LocalStorgae";
import Loading from "../../features/Placeholders/Loading";
import Toggle from "../../components/Toggle/Toggle";
import "../stylesheets/persona.scss";
import contentApi from "../../features/Personas/GeneralPersonaDashboard/components/Content/api/content";

export default function Personas() {
  const dispatch = useDispatch();
  const dashboard = "personas";

  const { t } = useTranslation();

  const storedFilter = JSON.parse(storage.getItem("selected"));
  const language = LocalStorage.getItem("selectedLanguage");

  const [isToggled, setIsToggled] = useState(() => {
    // Get the value from local storage or default to false if it doesn't exist
    const storedValue = storage.getItem("isToggled");
    return storedValue ? JSON.parse(storedValue) : false;
  });
  const [bodyParams, setBodyParams] = useState(null);
  const [pagesAndKeywordsParams, setPagesAndKeywordsParams] = useState(null);
  const [prevBodyParams, setPrevBodyParams] = useState(null);
  const [prevPagesAndKeywordsParams, setPrevPagesAndKeywordsParams] =
    useState(null);

  //content related states
  const [pageCount, setPageCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [content, setContent] = useState([]);
  const [contentLoading, setContentLoading] = useState(false);

  const dataValues = {
    personaLabel: "personaLabel",
    date: "date",
    countryCode: "country",
    countryName: "countryName",
    categoryInteraction: "categoryInteraction",
    age: "age",
    gender: "gender",
    cities: "city",
    totalSpend: "totalSpend",
    roomType: "roomType",
    source: "source",
    medium: "medium",
    campaign: "campaign",
    term: "term",
    content: "content",
    keywords: "keywords",
    longTailKeywords: "long_tail_keywords"
  };

  const filterTranslation = {
    Country: t("personaDashboard.country"),
    Personas: t("personaDashboard.interests"),
    Pages: t("analyticsPage.pagesTitle"),
    World: t("analyticsPage.worldTitle"),
    Categories: t("personaDashboard.categories"),
    Age: t("personaDashboard.age"),
    Gender: t("personaDashboard.gender"),
    Cities: t("personaDashboard.cities"),
    Spend: t("personaDashboard.spend"),
    Date: t("personaDashboard.Date"),
    Rooms: t("PMSPage.roomsTitle"),
    Source: t("personaDashboard.source"),
    Medium: t("personaDashboard.medium"),
    Campaign: t("personaDashboard.campaign"),
    Term: t("personaDashboard.term"),
    Content: t("personaDashboard.content"),
    Heatmap1: t("heatmap.heatmap_1"),
    Heatmap2: t("heatmap.heatmap_2"),
    Heatmap3: t("heatmap.heatmap_3"),
    Keywords: t("analyticsPage.keywords"),
    LongTailKeywords: t("analyticsPage.longTailKeywords")
  };

  const {
    allData: allData1,
    countriesData: countriesData1,
    filteredData: filteredData1,
    categoriesData: categoriesData1,
    ageData: ageData1,
    genderData: genderData1,
    citiesData: citiesData1,
    totalSpendData: totalSpendData1,
    selectedPersonaFilterType,
    roomsData: roomsData1,
    pageUrlData: pageUrlData1,
    sourceData: sourceData1,
    mediumData: mediumData1,
    campaignData: campaignData1,
    termData: termData1,
    contentData: contentData1,
    pmsToggleActivation,
    isLoading,
    selectedFilterTypeForHeatmap,
    companyType,
    keywordsData: keywordsData1,
    longTailKeyWordsData: longTailKeyWordsData1,
    pagesAndKewords: pagesAndKewords1,
    pagesAndKewordsFilteredData: pagesAndKewordsFilteredData1
  } = useSelector((state) => state.PersonasData);

  const {
    websiteID,
    dateRange,
    isCompare,
    timeType,
    compareDateRange,
    selectedPersonasFilters,
    createdBy
  } = useSelector((state) => state.generalData);

  const updatePersona = (personaGraphData) => {
    if (!selectedPersonasFilters[filterTranslation.Personas]) {
      dispatch(
        updatePersonaData({
          labels: personaGraphData.map((ele) => ele.label),
          visitors: personaGraphData.map((ele) => ele.visitors),
          views: personaGraphData.map((ele) => ele.views)
        })
      );
    }
  };

  const updateCategories = (categoryGraphData) => {
    if (selectedPersonasFilters[filterTranslation.Categories]) {
      dispatch(
        updateCategoriesData({
          ...filterAccordingToSelected(
            categoriesData1,
            selectedPersonasFilters[filterTranslation.Categories]
          )
        })
      );
    } else {
      dispatch(
        updateCategoriesData({
          labels: categoryGraphData.map((ele) => ele.key),
          dataset: categoryGraphData.map((ele) => ele.total),
          total: categoryGraphData.map((ele) => ele.total)
        })
      );
    }
  };

  const updateCountries = (countryGraphData) => {
    if (
      selectedPersonasFilters[filterTranslation.Country] ||
      selectedPersonasFilters[filterTranslation.World]
    ) {
      dispatch(
        updateCountriesData({
          ...filterAccordingToSelected(
            countriesData1,
            selectedPersonasFilters[filterTranslation.Country]
              ? selectedPersonasFilters[filterTranslation.Country]
              : selectedPersonasFilters[filterTranslation.World]
          )
        })
      );
    } else {
      dispatch(
        updateCountriesData({
          labels: countryGraphData.map((ele) => ele.label),
          dataset: countryGraphData.map((ele) => ele.visitors),
          visitors: countryGraphData.map((ele) => ele.visitors)
        })
      );
      dispatch(
        updateWorldData({
          labels: countryGraphData.map((ele) => ele.label),
          dataset: countryGraphData.map((ele) => ele.visitors)
        })
      );
    }
  };

  const updateViewsVisitors = (pageGraphData) => {
    dispatch(
      updateViewsVisitorsData({
        labels: pageGraphData.map((ele) => ele.label),
        views: pageGraphData.map((ele) => ele.views),
        visitors: pageGraphData.map((ele) => ele.visitors)
      })
    );
  };

  const updateAge = (ageGraphData) => {
    if (selectedPersonasFilters[filterTranslation.Age]) {
      dispatch(
        updateAgeData({
          ...filterAccordingToSelected(
            ageData1,
            selectedPersonasFilters[filterTranslation.Age]
          )
        })
      );
    } else {
      dispatch(
        updateAgeData({
          labels: ageGraphData.map((ele) => ele.label),
          dataset: ageGraphData.map((ele) => ele.visitors),
          visitors: ageGraphData.map((ele) => ele.visitors)
        })
      );
    }
  };

  const updateGender = (genderGraphData) => {
    if (selectedPersonasFilters[filterTranslation.Gender]) {
      dispatch(
        updateGenderData({
          ...filterAccordingToSelected(
            genderData1,
            selectedPersonasFilters[filterTranslation.Gender]
          )
        })
      );
    } else {
      dispatch(
        updateGenderData({
          labels: genderGraphData.map((ele) => ele.label),
          dataset: genderGraphData.map((ele) => ele.visitors),
          visitors: genderGraphData.map((ele) => ele.visitors)
        })
      );
    }
  };

  const updateCities = (citiesGraphData) => {
    if (selectedPersonasFilters[filterTranslation.Cities]) {
      dispatch(
        updateCitiesData({
          ...filterAccordingToSelected(
            citiesData1,
            selectedPersonasFilters[filterTranslation.Cities]
          )
        })
      );
    } else {
      dispatch(
        updateCitiesData({
          labels: citiesGraphData.map((ele) => ele.label),
          dataset: citiesGraphData.map((ele) => ele.visitors),
          visitors: citiesGraphData.map((ele) => ele.visitors)
        })
      );
    }
  };

  const updateTotalSpend = (totalSpendGraphData) => {
    if (selectedPersonasFilters[filterTranslation.Spend]) {
      dispatch(
        updateTotalSpendData({
          ...filterAccordingToSelected(
            totalSpendData1,
            selectedPersonasFilters[filterTranslation.Spend]
          )
        })
      );
    } else {
      dispatch(
        updateTotalSpendData({
          labels: totalSpendGraphData.map((ele) => ele.label),
          dataset: totalSpendGraphData.map((ele) => ele.visitors),
          visitors: totalSpendGraphData.map((ele) => ele.visitors)
        })
      );
    }
  };

  const updateVisitorsLine = (visitorsLineGraphData) => {
    if (!selectedPersonasFilters[filterTranslation.Date]) {
      dispatch(
        updateVisitorsLineData({
          labels: visitorsLineGraphData.map((ele) => ele.label),
          revenue: visitorsLineGraphData.map((ele) => ele.revenue),
          bookings: visitorsLineGraphData.map((ele) => ele.roomBook),
          requests: visitorsLineGraphData.map((ele) => ele.roomReq)
        })
      );
    }
  };

  const updateRooms = (roomGraphData) => {
    if (selectedPersonasFilters[filterTranslation.Rooms]) {
      dispatch(
        updateRoomsData({
          ...filterAccordingToPmsSelected(
            roomsData1,
            selectedPersonasFilters[filterTranslation.Rooms]
          )
        })
      );
    } else {
      dispatch(
        updateRoomsData({
          labels: roomGraphData?.map((ele) => ele.label),
          roomReq: roomGraphData?.map((ele) => ele.roomRequests),
          requests: roomGraphData?.map((ele) => ele.requests)
        })
      );
    }
  };

  const updatePageUrl = (pageURLGraphData) => {
    if (selectedPersonasFilters[filterTranslation.Pages]) {
      dispatch(
        updatePageUrlData({
          ...filterAccordingToSelected(
            pageUrlData1,
            selectedPersonasFilters[filterTranslation.Pages]
          )
        })
      );
    } else {
      dispatch(
        updatePageUrlData({
          labels: pageURLGraphData?.labels,
          visitors: pageURLGraphData?.visitors,
          views: pageURLGraphData?.views,
          bounce: pageURLGraphData?.bounce,
          preSales: pageURLGraphData?.preSalesEvent,
          sales: pageURLGraphData?.salesEvent,
          revenue: pageURLGraphData?.revenue
        })
      );
    }
  };

  const updateSource = (sourceGraphData) => {
    if (selectedPersonasFilters[filterTranslation.Source]) {
      dispatch(
        updateSourceData({
          ...filterAccordingToSelected(
            sourceData1,
            selectedPersonasFilters[filterTranslation.Source]
          )
        })
      );
    } else {
      dispatch(
        updateSourceData({
          labels: sourceGraphData.map((ele) => ele.label),
          dataset: sourceGraphData.map((ele) => ele.visitors),
          visitors: sourceGraphData.map((ele) => ele.visitors)
        })
      );
    }
  };

  const updateMedium = (mediumGraphData) => {
    if (selectedPersonasFilters[filterTranslation.Medium]) {
      dispatch(
        updateMediumData({
          ...filterAccordingToSelected(
            mediumData1,
            selectedPersonasFilters[filterTranslation.Medium]
          )
        })
      );
    } else {
      dispatch(
        updateMediumData({
          labels: mediumGraphData.map((ele) => ele.label),
          dataset: mediumGraphData.map((ele) => ele.visitors),
          visitors: mediumGraphData.map((ele) => ele.visitors)
        })
      );
    }
  };

  const updateCampaign = (campaignGraphData) => {
    if (selectedPersonasFilters[filterTranslation.Campaign]) {
      dispatch(
        updateCampaignData({
          ...filterAccordingToSelected(
            campaignData1,
            selectedPersonasFilters[filterTranslation.Campaign]
          )
        })
      );
    } else {
      dispatch(
        updateCampaignData({
          labels: campaignGraphData.map((ele) => ele.label),
          dataset: campaignGraphData.map((ele) => ele.visitors),
          visitors: campaignGraphData.map((ele) => ele.visitors)
        })
      );
    }
  };

  const updateTerm = (termGraphData) => {
    if (selectedPersonasFilters[filterTranslation.Term]) {
      dispatch(
        updateTermData({
          ...filterAccordingToSelected(
            termData1,
            selectedPersonasFilters[filterTranslation.Term]
          )
        })
      );
    } else {
      dispatch(
        updateTermData({
          labels: termGraphData.map((ele) => ele.label),
          dataset: termGraphData.map((ele) => ele.visitors),
          visitors: termGraphData.map((ele) => ele.visitors)
        })
      );
    }
  };

  const updateContent = (contentGraphData) => {
    if (selectedPersonasFilters[filterTranslation.Content]) {
      dispatch(
        updateContentData({
          ...filterAccordingToSelected(
            contentData1,
            selectedPersonasFilters[filterTranslation.Content]
          )
        })
      );
    } else {
      dispatch(
        updateContentData({
          labels: contentGraphData.map((ele) => ele.label),
          dataset: contentGraphData.map((ele) => ele.visitors),
          visitors: contentGraphData.map((ele) => ele.visitors)
        })
      );
    }
  };

  const updateKeywords = (keywords) => {
    if (selectedPersonasFilters[filterTranslation.Keywords]) {
      dispatch(
        updateKeywordsData({
          ...filterAccordingToSelected(
            keywordsData1,
            selectedPersonasFilters[filterTranslation.Keywords]
          )
        })
      );
    } else {
      dispatch(
        updateKeywordsData({
          labels: keywords.map((ele) => ele.label),
          dataset: keywords.map((ele) => ele.visitors),
          visitors: keywords.map((ele) => ele.visitors)
        })
      );
    }
  };

  const updateLongTailKeywords = (longTailKeywords) => {
    if (selectedPersonasFilters[filterTranslation.LongTailKeywords]) {
      dispatch(
        updateLongTailKeyWordsData({
          ...filterAccordingToSelected(
            longTailKeyWordsData1,
            selectedPersonasFilters[filterTranslation.LongTailKeywords]
          )
        })
      );
    } else {
      dispatch(
        updateLongTailKeyWordsData({
          labels: longTailKeywords.map((ele) => ele.label),
          dataset: longTailKeywords.map((ele) => ele.visitors),
          visitors: longTailKeywords.map((ele) => ele.visitors)
        })
      );
    }
  };

  const updatePagesAndKeywordsData = (data) => {
    const keywords = getKeywords(
      data,
      selectedPersonasFilters[filterTranslation.LongTailKeywords]
        ? selectedPersonasFilters[filterTranslation.LongTailKeywords]
        : []
    );
    const longTailKeywords = getLongtailKeywords(
      data,
      selectedPersonasFilters[filterTranslation.Keywords]
        ? selectedPersonasFilters[filterTranslation.Keywords]
        : []
    );
    const pageURLGraphData = aggregateDataByCurrentLink(data);
    updateKeywords(keywords);
    updateLongTailKeywords(longTailKeywords);
    updatePageUrl(pageURLGraphData);
  };

  const separateData = (data) => {
    const personaGraphData = getGraphDataSorted(
      dataFilterFunction(data, dataValues.personaLabel),
      { visitors: true, views: true }
    );

    const countryGraphData = getGraphDataSorted(
      dataFilterFunction(data, dataValues.countryName),
      { visitors: true }
    );

    const pageGraphData = getViewsVisitorsData(
      dataFilterFunction(data, dataValues.date),
      isCompare ? compareDateRange : dateRange,
      timeType
    );

    const categoryGraphData = separatePersonaCategory(data);

    const ageGraphData = getGraphDataSorted(
      dataFilterFunction(data, dataValues.age),
      { visitors: true }
    );

    const genderGraphData = getGraphDataSorted(
      dataFilterFunction(data, dataValues.gender),
      { visitors: true }
    );

    const citiesGraphData = getGraphDataSorted(
      dataFilterFunction(data, dataValues.cities),
      { visitors: true }
    );

    const totalSpendGraphData = getGraphDataSorted(
      dataFilterFunction(data, dataValues.totalSpend),
      { visitors: true }
    );

    const visitorsLineGraphData = processDataByDate(
      // dataFilterFunction(data, dataValues.date),
      isCompare ? compareDateRange : dateRange,
      selectedPersonaFilterType,
      data
    );

    const sourceGraphData = getGraphDataSorted(
      dataFilterFunction(data, dataValues.source),
      { visitors: true }
    );

    const mediumGraphData = getGraphDataSorted(
      dataFilterFunction(data, dataValues.medium),
      { visitors: true }
    );

    const campaignGraphData = getGraphDataSorted(
      dataFilterFunction(data, dataValues.campaign),
      { visitors: true }
    );
    const termGraphData = getGraphDataSorted(
      dataFilterFunction(data, dataValues.term),
      { visitors: true }
    );
    const contentGraphData = getGraphDataSorted(
      dataFilterFunction(data, dataValues.content),
      { visitors: true }
    );

    updatePersona(personaGraphData);
    updateCategories(categoryGraphData);
    updateCountries(countryGraphData);
    updateViewsVisitors(pageGraphData);
    updateSource(sourceGraphData);
    updateMedium(mediumGraphData);
    updateCampaign(campaignGraphData);
    updateTerm(termGraphData);
    updateContent(contentGraphData);
    dispatch(
      updateHeatmap1Data(
        getHeatmap1Data(data, selectedFilterTypeForHeatmap.toLowerCase(), t)
      )
    );

    dispatch(
      updateHeatmap2Data(
        getHeatmap2Data(
          data,
          selectedFilterTypeForHeatmap.toLowerCase(),
          companyType,
          t
        )
      )
    );
    dispatch(
      updateHeatmap3Data(
        getHeatmap3Data(
          data,
          selectedFilterTypeForHeatmap.toLowerCase(),
          companyType,
          t
        )
      )
    );

    if (isToggled) {
      const graphData = new GraphData();
      graphData.compute(data, dataValues);

      const roomGraphData = graphData.getGraphData(dataValues.roomType);

      updateRooms(roomGraphData);
      updateAge(ageGraphData);
      updateGender(genderGraphData);
      updateCities(citiesGraphData);
      updateTotalSpend(totalSpendGraphData);
      if (!selectedPersonasFilters[filterTranslation.Date]) {
        dispatch(updateVisitorsLineData(visitorsLineGraphData));
      }
      // updateVisitorsLine(visitorsLineGraphData);
    }
  };

  const filterData = (data, { entry, exit }) => {
    let tempData = data;

    const paramsSelected = {};

    const applyDateFilter = (dateField) => {
      if (selectedPersonasFilters[filterTranslation.Date]) {
        const filterValues = selectedPersonasFilters[filterTranslation.Date];
        tempData = tempData?.filter((ele) => {
          const formattedDate = formatDate(
            ele[dateField],
            selectedPersonaFilterType
          );
          return formattedDate && filterValues.includes(formattedDate);
        });
      }
    };

    applyDateFilter(dataValues.date);

    if (selectedPersonasFilters[filterTranslation.Country]) {
      paramsSelected.country =
        selectedPersonasFilters[filterTranslation.Country];
      tempData = tempData?.filter((ele) =>
        selectedPersonasFilters[filterTranslation.Country].includes(
          ele.countryName
        )
      );
    }
    if (selectedPersonasFilters[filterTranslation.World]) {
      paramsSelected.country = selectedPersonasFilters[filterTranslation.World];
      tempData = tempData?.filter((ele) =>
        selectedPersonasFilters[filterTranslation.World].includes(
          ele.countryName
        )
      );
    }

    if (selectedPersonasFilters[filterTranslation.Cities]) {
      paramsSelected.cities = selectedPersonasFilters[filterTranslation.Cities];
      tempData = tempData?.filter((ele) =>
        selectedPersonasFilters[filterTranslation.Cities].includes(ele.city)
      );
    }

    if (selectedPersonasFilters[filterTranslation.Personas]) {
      paramsSelected.persona =
        selectedPersonasFilters[filterTranslation.Personas];
      tempData = tempData?.filter((ele) =>
        selectedPersonasFilters[filterTranslation.Personas].includes(
          ele.personaLabel
        )
      );
    }

    if (selectedPersonasFilters[filterTranslation.Categories]) {
      paramsSelected.category =
        selectedPersonasFilters[filterTranslation.Categories];
      tempData = tempData?.filter((ele) =>
        // Iterate over each selected category
        selectedPersonasFilters[filterTranslation.Categories].some(
          (category) => {
            // Check if the category exists in the CategoryInteraction object
            return ele.CategoryInteraction && ele.CategoryInteraction[category];
          }
        )
      );
    }

    if (selectedPersonasFilters[filterTranslation.Age]) {
      paramsSelected.age = selectedPersonasFilters[filterTranslation.Age];
      tempData = tempData?.filter((ele) =>
        selectedPersonasFilters[filterTranslation.Age].includes(ele.age)
      );
    }

    if (selectedPersonasFilters[filterTranslation.Gender]) {
      paramsSelected.gender = selectedPersonasFilters[filterTranslation.Gender];
      tempData = tempData?.filter((ele) =>
        selectedPersonasFilters[filterTranslation.Gender].includes(ele.gender)
      );
    }

    if (selectedPersonasFilters[filterTranslation.Spend]) {
      paramsSelected.spend = selectedPersonasFilters[filterTranslation.Spend];
      tempData = tempData?.filter((ele) =>
        selectedPersonasFilters[filterTranslation.Spend].includes(
          ele.totalSpend
        )
      );
    }

    if (selectedPersonasFilters[filterTranslation.Rooms]) {
      paramsSelected.roomType =
        selectedPersonasFilters[filterTranslation.Rooms];
      tempData = tempData?.filter((ele) =>
        selectedPersonasFilters[filterTranslation.Rooms].includes(ele.roomType)
      );
    }

    if (selectedPersonasFilters[filterTranslation.Source]) {
      paramsSelected.source = selectedPersonasFilters[filterTranslation.Source];
      tempData = tempData?.filter((ele) =>
        selectedPersonasFilters[filterTranslation.Source].includes(ele.source)
      );
    }

    if (selectedPersonasFilters[filterTranslation.Medium]) {
      paramsSelected.medium = selectedPersonasFilters[filterTranslation.Medium];
      tempData = tempData?.filter((ele) =>
        selectedPersonasFilters[filterTranslation.Medium].includes(ele.medium)
      );
    }

    if (selectedPersonasFilters[filterTranslation.Campaign]) {
      paramsSelected.campaign =
        selectedPersonasFilters[filterTranslation.Campaign];
      tempData = tempData?.filter((ele) =>
        selectedPersonasFilters[filterTranslation.Campaign].includes(
          ele.campaign
        )
      );
    }

    if (selectedPersonasFilters[filterTranslation.Term]) {
      paramsSelected.term = selectedPersonasFilters[filterTranslation.Term];
      tempData = tempData?.filter((ele) =>
        selectedPersonasFilters[filterTranslation.Term].includes(ele.term)
      );
    }

    if (selectedPersonasFilters[filterTranslation.Content]) {
      paramsSelected.content =
        selectedPersonasFilters[filterTranslation.Content];
      tempData = tempData?.filter((ele) =>
        selectedPersonasFilters[filterTranslation.Content].includes(ele.content)
      );
    }

    if (selectedPersonasFilters[filterTranslation.Heatmap1]) {
      paramsSelected.guest_type =
        selectedPersonasFilters[filterTranslation.Heatmap1];
      tempData = tempData?.filter((ele) =>
        selectedPersonasFilters[filterTranslation.Heatmap1].includes(
          ele.guest_type
        )
      );
    }

    if (selectedPersonasFilters[filterTranslation.Heatmap2]) {
      paramsSelected.main_category_label =
        selectedPersonasFilters[filterTranslation.Heatmap2];
      tempData = tempData?.filter((ele) =>
        selectedPersonasFilters[filterTranslation.Heatmap2].includes(
          ele.main_category_label
        )
      );
    }

    if (selectedPersonasFilters[filterTranslation.Heatmap3]) {
      paramsSelected.main_subcategory_label =
        selectedPersonasFilters[filterTranslation.Heatmap3];
      tempData = tempData?.filter((ele) =>
        selectedPersonasFilters[filterTranslation.Heatmap3].includes(
          ele.main_subcategory_label
        )
      );
    }

    if (entry) {
      paramsSelected.entry = true;
      tempData = tempData?.filter((ele) => ele.entry === 1);
    } else if (exit) {
      paramsSelected.exit = true;
      tempData = tempData?.filter((ele) => ele.exit === 1);
    } else if (!entry && !exit) {
      tempData = [...(tempData || [])];
    }

    setBodyParams(
      Object.keys(paramsSelected).length === 0 ? null : paramsSelected
    );

    return tempData;
  };

  const filterKeywordsAndPagesData = (data) => {
    let tempData = data;
    if (selectedPersonasFilters[filterTranslation.Keywords]) {
      tempData = tempData.filter((ele) => {
        if (ele?.keywords) {
          const keywordsArray = ele?.keywords
            .split(",")
            .map((keywordItem) => keywordItem.trim());
          return keywordsArray.some((keyword) =>
            selectedPersonasFilters[filterTranslation.Keywords].includes(
              keyword
            )
          );
        }
      });
    }

    if (selectedPersonasFilters[filterTranslation.LongTailKeywords]) {
      tempData = tempData?.filter((ele) => {
        const longTails = Object.values(ele.long_tail_keywords || {});
        return longTails.some((keyword) =>
          selectedPersonasFilters[filterTranslation.LongTailKeywords].includes(
            keyword
          )
        );
      });
    }
    if (selectedPersonasFilters[filterTranslation.Pages]) {
      tempData = tempData?.filter((ele) =>
        selectedPersonasFilters[filterTranslation.Pages].includes(
          ele.current_link
        )
      );
    }
    return tempData;
  };

  useEffect(() => {
    dispatch(updateFetchFilterData(false));
  }, [dateRange, websiteID, t, isToggled]);

  const compareParams = (objA, objB) => {
    if (objA === null || objB === null) return false;
    if (Object.keys(objA).length !== Object.keys(objB).length) return false;

    for (let key in objA) {
      if (objA[key] !== objB[key]) return false;
    }
    return true;
  };

  // pages and keywords
  useEffect(() => {
    if (
      (compareParams(bodyParams, prevBodyParams) &&
        Object.keys(bodyParams ?? {})?.length === 1) ||
      (!compareParams(bodyParams, prevBodyParams) &&
        Object.keys(bodyParams ?? {})?.length === 1 &&
        Object.keys(prevBodyParams ?? {})?.length === 1) ||
      prevBodyParams === null ||
      bodyParams === null
    ) {
      dispatch(
        getAnalyticsPages(
          {
            website_id: websiteID,
            customer_id: createdBy,
            dateRange: [{ ...dateRange }],
            ...(bodyParams || {}),
            personaApi: isToggled
          },
          t
        )
      );
    }
    setPrevBodyParams(bodyParams);
  }, [bodyParams, dateRange, websiteID, t, isToggled]);

  // get general dashboard data
  useEffect(() => {
    if (
      (compareParams(pagesAndKeywordsParams, prevPagesAndKeywordsParams) &&
        Object.keys(pagesAndKeywordsParams ?? {})?.length === 1) ||
      (!compareParams(pagesAndKeywordsParams, prevPagesAndKeywordsParams) &&
        Object.keys(pagesAndKeywordsParams ?? {})?.length === 1 &&
        Object.keys(prevPagesAndKeywordsParams ?? {})?.length === 1) ||
      prevPagesAndKeywordsParams === null ||
      pagesAndKeywordsParams === null
    ) {
      dispatch(
        getPersonasData({
          website_id: websiteID,
          dateRange: [{ ...dateRange }],
          pmsData: isToggled,
          customer_id: createdBy,
          ...(pagesAndKeywordsParams || {})
        })
      );
    }
    setPrevPagesAndKeywordsParams(pagesAndKeywordsParams);
  }, [
    pagesAndKeywordsParams,
    isCompare,
    dateRange,
    websiteID,
    language,
    isToggled
  ]);

  useEffect(() => {
    if (pagesAndKewords1?.data) {
      updatePagesAndKeywordsData(pagesAndKewords1?.data);
    }
  }, [
    pagesAndKewords1?.data,
    selectedPersonasFilters?.[filterTranslation.Keywords],
    selectedPersonasFilters?.[filterTranslation.LongTailKeywords],
    selectedPersonasFilters?.[filterTranslation.Pages]
  ]);

  useEffect(() => {
    if (allData1.length > 0) {
      separateData(allData1);
      dispatch(updateFetchFilterData(false));
    }
  }, [allData1, t, language, selectedPersonaFilterType]);

  const getPagesAndKeywordsParams = () => {
    const keywordParamsSelected = {};
    if (selectedPersonasFilters?.[filterTranslation.Keywords]) {
      keywordParamsSelected.keywords =
        selectedPersonasFilters[filterTranslation.Keywords];
    }
    if (selectedPersonasFilters?.[filterTranslation.LongTailKeywords]) {
      keywordParamsSelected.long_tail_keywords =
        selectedPersonasFilters?.[filterTranslation.LongTailKeywords];
    }
    if (selectedPersonasFilters?.[filterTranslation.Pages]) {
      keywordParamsSelected.pages =
        selectedPersonasFilters?.[filterTranslation.Pages];
    }
    return keywordParamsSelected;
  };

  const getBodyParams = () => {
    const bodyParamsSelected = selectedPersonasFilters;
    if (selectedPersonasFilters?.[filterTranslation.Keywords]) {
      delete bodyParamsSelected[
        selectedPersonasFilters[filterTranslation.Keywords]
      ];
    }
    if (selectedPersonasFilters?.[filterTranslation.LongTailKeywords]) {
      delete bodyParamsSelected[
        selectedPersonasFilters?.[filterTranslation.LongTailKeywords]
      ];
    }
    if (selectedPersonasFilters?.[filterTranslation.Pages]) {
      delete bodyParamsSelected[
        selectedPersonasFilters?.[filterTranslation.Pages]
      ];
    }
    return bodyParamsSelected;
  };

  useEffect(() => {
    if (Object.keys(selectedPersonasFilters)?.length === 0) {
      setBodyParams(null);
      setPagesAndKeywordsParams(null);
    }
    if (
      selectedPersonasFilters?.[filterTranslation.Keywords] ||
      selectedPersonasFilters?.[filterTranslation.LongTailKeywords] ||
      selectedPersonasFilters?.[filterTranslation.Pages]
    ) {
      const params = getPagesAndKeywordsParams();
      if (JSON.stringify(params) !== JSON.stringify(pagesAndKeywordsParams)) {
        setPagesAndKeywordsParams(params);
      }
      dispatch(
        updatePagesAndKewordsFilteredData(
          filterKeywordsAndPagesData(pagesAndKewords1?.data)
        )
      );
    }
    dispatch(
      updateFilteredData(
        filterData(allData1, {
          entry: selectedPersonasFilters?.Filter?.includes(
            t("analyticsPage.entryPages")
          ),
          exit: selectedPersonasFilters?.Filter?.includes(
            t("analyticsPage.exitPages")
          )
        })
      )
    );
    const bodyParams = getBodyParams();
    setPrevBodyParams(bodyParams);
  }, [selectedPersonasFilters, t, language]);

  useEffect(() => {
    if (selectedFilterTypeForHeatmap === "Visitors") {
      dispatch(updateHeatmap1Data(getHeatmap1Data(allData1, "visitors", t)));
      dispatch(
        updateHeatmap2Data(
          getHeatmap2Data(allData1, "visitors", companyType, t)
        )
      );
      dispatch(
        updateHeatmap3Data(
          getHeatmap3Data(allData1, "visitors", companyType, t)
        )
      );
    }
    if (selectedFilterTypeForHeatmap === "Views") {
      dispatch(updateHeatmap1Data(getHeatmap1Data(allData1, "views", t)));
      dispatch(
        updateHeatmap2Data(getHeatmap2Data(allData1, "views", companyType, t))
      );
      dispatch(
        updateHeatmap3Data(getHeatmap3Data(allData1, "views", companyType, t))
      );
    }
    if (selectedFilterTypeForHeatmap === "Revenue") {
      dispatch(updateHeatmap1Data(getHeatmap1Data(allData1, "revenue", t)));
      dispatch(
        updateHeatmap2Data(getHeatmap2Data(allData1, "revenue", companyType, t))
      );
      dispatch(
        updateHeatmap3Data(getHeatmap3Data(allData1, "revenue", companyType, t))
      );
    }
  }, [selectedFilterTypeForHeatmap, allData1]);

  useEffect(() => {
    if (filteredData1.length > 0) {
      separateData(filteredData1);
    }
    if (
      pagesAndKewordsFilteredData1.length > 0 &&
      (selectedPersonasFilters?.[filterTranslation.Keywords] ||
        selectedPersonasFilters?.[filterTranslation.LongTailKeywords] ||
        selectedPersonasFilters?.[filterTranslation.Pages])
    ) {
      updatePagesAndKeywordsData(pagesAndKewordsFilteredData1);
      dispatch(updateFetchFilterData(true));
    }
    storage.setItem("selected", JSON.stringify(selectedPersonasFilters));
  }, [allData1, filteredData1, pagesAndKewordsFilteredData1, t, language]);

  // saving the selected filters fetched from local storage
  useEffect(() => {
    if (allData1.length > 0) {
      dispatch(updateSelectedPersonasFilters(storedFilter ?? {}));
    }
  }, [allData1]);

  // Update local storage whenever isToggled changes
  useEffect(() => {
    storage.setItem("isToggled", JSON.stringify(isToggled));
  }, [isToggled]);

  const handlePagination = (event, page) => {
    setPageNumber(page);
  };

  const apiHandle = async () => {
    try {
      setContentLoading(true);
      const res = await contentApi({
        website_id: websiteID,
        dateRange: [dateRange],
        pageNumber,
        pmsData: isToggled,
        longtailKeywordFilter:
          selectedPersonasFilters?.[t("analyticsPage.longTailKeywords")]
      });
      if (res?.data?.status === 200) {
        setContent(res.data.result[0].result);
        setPageCount(res.data.result[0].pageCount);
        setContentLoading(false);
      }
    } catch (error) {
      setContentLoading(false);
    }
  };

  useEffect(() => {
    if (
      selectedPersonasFilters?.[t("analyticsPage.longTailKeywords")]?.length >
        0 &&
      t("analyticsPage.longTailKeywords") in selectedPersonasFilters
    ) {
      apiHandle();
    }
    if (
      selectedPersonasFilters?.[t("analyticsPage.longTailKeywords")]?.length ===
      0
    ) {
      setContent([]);
    }
  }, [pagesAndKeywordsParams, pageNumber]);

  return (
    <div>
      <AuthorizedHeader />
      <div className="dashboard-body">
        <div className="container">
          <div className="flex-end">
            {/* <h3 className="persona-heading">{t("personaDashboard.title")}</h3> */}
            {pmsToggleActivation && (
              <Toggle
                isToggled={isToggled}
                setIsToggled={setIsToggled}
                label={t("personaDashboard.toggleLabel")}
              />
            )}
          </div>
          <div className="flex-between filter-header">
            <FilterPill
              filterValues={selectedPersonasFilters}
              dashboard={dashboard}
            />
            <DateCalendar />
          </div>

          {isLoading && <Loading />}

          {!isLoading && (
            <GenaralDashboard
              dashboard={dashboard}
              isCompare={isCompare}
              selectedPersonaFilters={selectedPersonasFilters}
              isToggled={isToggled}
              updateSelectedPersonasFilters={updateSelectedPersonasFilters}
              pageCount={pageCount}
              pageNumber={pageNumber}
              handlePagination={handlePagination}
              content={content}
              contentLoading={contentLoading}
            />
          )}
        </div>
      </div>
    </div>
  );
}
