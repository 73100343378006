/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import AddBarGraph from "../../../components/ChartComponents/GeneralBarGraph";
import StackedChart from "../../../components/ChartComponents/StackedChart";
import WorldMap from "../../../components/ChartComponents/WorldMap";
import LineChart from "../DashBoardLandingPage/LineChart";
import NoDataFound from "../../Placeholders/NoDataFound";
import PersonaLabel from "../../../components/PersonaLabel";
import { findPersonaDescription } from "../../../utils/findPersonaDescription";
import Insights from "../../../components/Insights";
import SourceBarGraph from "../../Personas/GeneralPersonaDashboard/components/SourceBarGraph";
import MediumBarGraph from "../../Personas/GeneralPersonaDashboard/components/MediumBarGraph";
import CampaignBarGraph from "../../Personas/GeneralPersonaDashboard/components/CampaignBarGraph";
import TermBarGraph from "../../Personas/GeneralPersonaDashboard/components/TermBarGraph";
import ContentBarGraph from "../../Personas/GeneralPersonaDashboard/components/ContentBarGraph";
import LimitExceeded from "../../Placeholders/LimitExceeded";
import CollectingData from "../../../assets/icons/collectingData.svg";
import { useLocation } from "react-router-dom";
import HeatMapWithLable from "../../../components/HeatMapWithLabels";
export default function GeneralDashboard({
  isCompare,
  selectedFilters,
  insightsTitle,
  dashboard,
  updateSelectedFilters
}) {
  const {
    allData,
    insights,
    personaData,
    devicesData,
    browsersData,
    countriesData,
    pageUrlData,
    pageURLGraphData,
    worldData,
    viewsVisitorsData,
    graphSepData,
    sourceData,
    mediumData,
    campaignData,
    termData,
    contentData,
    limitExceeded,
    heatmap1Data,
    heatmap2Data,
    heatmap3Data,
    sourceLinkData,
    selectedFilterForHeatmap
  } = useSelector((state) => state.dashboardData);

  const location = useLocation();
  const dispatch = useDispatch();

  const [limitedFilteredData, setLimitedFilteredData] = useState([]);
  const { websiteID } = useSelector((state) => state?.generalData);

  useEffect(() => {
    setLimitedFilteredData(pageUrlData);
  }, [pageUrlData]);

  const handleSearch = (searchTerm) => {
    // Check if the search term is provided
    if (!searchTerm || searchTerm.length <= 2) {
      setLimitedFilteredData(pageUrlData); // Reset to original data if no search term
      return;
    }

    const query = searchTerm?.toLowerCase();
    const {
      bounce,
      dataset,
      labels,
      links,
      preSales,
      revenue,
      sales,
      views,
      visitors
    } = pageUrlData;

    // Filter indices where the labels match the search string
    const filteredIndices = labels
      .map((label, index) => {
        if (label.toLowerCase().includes(query)) {
          return index;
        }
        return -1;
      })
      .filter((index) => index !== -1);

    // If no matches found, show everything
    if (filteredIndices?.length === 0) {
      setLimitedFilteredData(pageUrlData);
      return;
    }

    // Create a new object with filtered arrays
    const filteredData = {
      bounce: filteredIndices.map((index) => bounce[index]),
      dataset: filteredIndices.map((index) => dataset[index]),
      labels: filteredIndices.map((index) => labels[index]),
      links: filteredIndices.map((index) => links[index]),
      preSales: filteredIndices.map((index) => preSales[index]),
      revenue: filteredIndices.map((index) => revenue[index]),
      sales: filteredIndices.map((index) => sales[index]),
      views: filteredIndices.map((index) => views[index]),
      visitors: filteredIndices.map((index) => visitors[index])
    };

    setLimitedFilteredData(filteredData);
  };

  const commonProps = {
    isCompare,
    dashboard,
    filterValues: selectedFilters
  };

  const { t } = useTranslation();

  // to get the personaDescription
  const personaDescription = findPersonaDescription(
    allData,
    commonProps.filterValues,
    t
  );

  if (limitExceeded) return <LimitExceeded />;

  if (allData.length > 0)
    return (
      <>
        <Insights
          insightsTitle={insightsTitle}
          isCompare={isCompare}
          insights={insights}
        />
        {heatmap2Data?.length && heatmap3Data?.length && (
          <HeatMapWithLable
            heatmap1Data={heatmap1Data}
            heatmap2Data={heatmap2Data}
            heatmap3Data={heatmap3Data}
            isToggled={false}
            dashboard={dashboard}
            selectedFilters={selectedFilters}
            updateFilters={updateSelectedFilters}
            filterType={selectedFilterForHeatmap}
          />
        )}
        <div className="position-relative">
          {personaData?.labels?.length === 1 &&
            personaData?.labels?.includes(t("common.other")) && (
              <div className="illustration-background">
                <img
                  src={CollectingData}
                  alt="collecting-data-Icon"
                  className="illustration-icon"
                />
                <span className="illustration-heading">
                  {t("common.collectingYourData")}
                </span>
              </div>
            )}
          <div className="flex-column gap-10">
            <StackedChart
              title={t("analyticsPage.interests")}
              views={personaData?.views}
              visitors={personaData?.visitors}
              labels={personaData?.labels}
              updatedComponentFilters={updateSelectedFilters}
              selectedFilters={selectedFilters}
            />
            {/* <PersonaLabel personaDescription={personaDescription} /> */}
          </div>
        </div>
        <div className="grid grid-template general-bar-graph-height">
          <AddBarGraph
            loading={graphSepData?.isLoading}
            title={t("analyticsPage.devicesTitle")}
            labels={devicesData?.labels}
            // subtitle={t("analyticsPage.devicesSubtitle")}
            numBars={
              devicesData?.labels?.length < 6 ? devicesData?.labels?.length : 6
            }
            data={{
              tooltip: t("analyticsPage.visitors"),
              values: devicesData?.visitors
            }}
            columnData={[
              {
                name: t("analyticsPage.visitors"),
                diff: devicesData?.visitors,
                number: true
              }
            ]}
            {...commonProps}
          />
          <AddBarGraph
            loading={graphSepData?.isLoading}
            labels={browsersData?.labels}
            title={t("analyticsPage.browsersTitle")}
            // subtitle={t("analyticsPage.browsersSubtitle")}
            numBars={
              browsersData?.labels?.length < 6
                ? browsersData?.labels?.length
                : 6
            }
            data={{
              tooltip: t("analyticsPage.visitors"),
              values: browsersData?.visitors
            }}
            columnData={[
              {
                name: t("analyticsPage.visitors"),
                diff: browsersData?.visitors,
                number: true
              }
            ]}
            {...commonProps}
          />
          <AddBarGraph
            loading={graphSepData?.isLoading}
            labels={countriesData.labels}
            title={t("analyticsPage.countryTitle")}
            // subtitle={t("analyticsPage.countryTitle")}
            numBars={
              countriesData?.labels?.length < 6
                ? countriesData?.labels?.length
                : 6
            }
            data={{
              tooltip: t("analyticsPage.visitors"),
              values: countriesData?.visitors
            }}
            columnData={[
              {
                name: t("analyticsPage.visitors"),
                diff: countriesData?.visitors,
                number: true
              }
            ]}
            {...commonProps}
          />
        </div>
        {/* World map */}
        <div className="">
          <WorldMap
            data={worldData?.dataset}
            labels={worldData.labels}
            title={t("analyticsPage.worldTitle")}
            selectedFilters={selectedFilters}
            updateFilters={updateSelectedFilters}
          />
        </div>
        {/* World map ends */}

        <LineChart
          views={viewsVisitorsData?.views}
          visitors={viewsVisitorsData?.visitors}
          labels={viewsVisitorsData?.labels}
          title={t("analyticsPage.visitorsPageViewsTitle")}
        />

        <div className="grid half-column-grid">
          <div className="general-bar-graph-height">
            <AddBarGraph
              loading={sourceLinkData?.isLoading}
              labels={sourceLinkData.labels}
              title={t("analyticsPage.sourceLink")}
              numBars={
                sourceLinkData?.labels?.length < 6
                  ? sourceLinkData?.labels?.length
                  : 6
              }
              data={{
                tooltip: t("analyticsPage.visitors"),
                values: sourceLinkData?.visitors
              }}
              columnData={[
                {
                  name: t("analyticsPage.visitors"),
                  diff: sourceLinkData?.visitors,
                  number: true
                }
              ]}
              {...commonProps}
            />
          </div>
          <div className="general-bar-graph-height">
            <SourceBarGraph
              sourceData={sourceData}
              commonProps={commonProps}
              t={t}
            />
          </div>
        </div>
        <div className="grid half-column-grid">
          <div className="general-bar-graph-height">
            <MediumBarGraph
              mediumData={mediumData}
              commonProps={commonProps}
              t={t}
            />
          </div>
          <div className="general-bar-graph-height">
            <CampaignBarGraph
              campaignData={campaignData}
              commonProps={commonProps}
              t={t}
            />
          </div>
        </div>
        <div className="grid half-column-grid">
          <div className="general-bar-graph-height">
            <TermBarGraph termData={termData} commonProps={commonProps} t={t} />
          </div>
          <div className="general-bar-graph-height">
            <ContentBarGraph
              contentData={contentData}
              commonProps={commonProps}
              t={t}
            />
          </div>
        </div>

        <div className="minimum-height-for-pages">
          <AddBarGraph
            loading={pageURLGraphData?.isLoading}
            labels={limitedFilteredData?.labels}
            title={t("analyticsPage.pagesTitle")}
            subtitle={t("analyticsPage.pagesTitle")}
            limitedFilteredData={limitedFilteredData}
            setLimitedFilteredData={setLimitedFilteredData}
            pageUrlDataNew={pageUrlData}
            search={true}
            handleSearch={handleSearch}
            data={{
              tooltip: t("analyticsPage.visitors"),
              // values: pageUrlData?.visitors
              values: limitedFilteredData?.visitors
            }}
            // labels={pageUrlData?.labels}
            // numBars={
            //   pageUrlData?.labels?.length < 25 ? pageUrlData?.labels?.length : 25
            // }
            numBars={
              limitedFilteredData?.labels?.length < 25
                ? limitedFilteredData?.labels?.length
                : 25
            }
            columnData={[
              {
                id: 1,
                name: t("analyticsPage.visitors"),
                // diff: pageUrlData?.visitors,
                diff: limitedFilteredData?.visitors,
                number: true
              },
              {
                id: 2,
                name: t("analyticsPage.views"),
                // diff: pageUrlData?.views,
                diff: limitedFilteredData?.views,
                number: true
              },
              {
                id: 3,
                name: t("analyticsPage.bounce"),
                // diff: pageUrlData?.bounce,
                diff: limitedFilteredData?.bounce,
                percent: true
              },
              {
                id: 4,
                name: t("analyticsPage.preSalesEvent"),
                // diff: pageUrlData?.preSales,
                diff: limitedFilteredData?.preSales,
                number: true
              },
              {
                id: 5,
                name: t("analyticsPage.salesEvent"),
                // diff: pageUrlData?.sales,
                diff: limitedFilteredData?.sales,
                number: true
              },
              {
                id: 6,
                name: t("analyticsPage.revenue"),
                // diff: pageUrlData?.revenue,
                diff: limitedFilteredData?.revenue,
                euro: true
              }
            ]}
            {...commonProps}
          />
        </div>
      </>
    );

  return <NoDataFound />;
}

GeneralDashboard.propTypes = {
  isCompare: PropTypes.bool,
  selectedFilters: PropTypes.oneOfType([Object]),
  insightsTitle: PropTypes.oneOfType([Object]),
  dashboard: PropTypes.string,
  updateSelectedFilters: PropTypes.func
};
