/* eslint-disable */
import React from "react";
import ReactJsxParser from "react-jsx-parser";
import "../assets/stylesheets/webImageAdvertisement.scss";

export default function WebImageAdvertisement({
  webImages,
  setOpenedImage,
  setSelectedImage,
  setSelectedName,
  setSelectedDimension,
  selectedCampaign,
  navigationFrom,
  orginalTemplate,
  orginalTemplateCreate,
  templateRefs,
  selectedImage
}) {

  const handleImageLoad = (e) => {
    const { naturalWidth, naturalHeight } = e.target;
    // if(navigationFrom === "createFlow") {
      setSelectedDimension(
        `${naturalWidth}x${naturalHeight}`
      );
    // }
  };

  // let firstTemplate = webImages?.website[0]?.template;
  // console.log("firstTemplate", firstTemplate);
  return (
    <div className="flex-justify-pointer">
      <div className="flex web-image-advertisement">
        <div
         className="padding-bottom-10"
          onClick={() => {
            setOpenedImage(true);
            if (navigationFrom == "createFlow") {
              setSelectedImage(webImages);
                // setSelectedName(orginalTemplateCreate?.website[0]?.name);
                // setSelectedDimension(
                //   orginalTemplateCreate?.website[0]?.dimensions
                // );
            } else {
              setSelectedImage(selectedCampaign?.advertisment_image);
              // setSelectedName(selectedCampaign?.advertisment_image[0]?.name);
              setSelectedDimension(
                selectedCampaign?.dimensions
              );
            }
          }}
          role="button"
          tabIndex={0}
          onKeyDown={(e) => {
            // Handling keyboard events
            if (e.key === "Enter" || e.key === " ") {
              setOpenedImage(true);
              if (navigationFrom == "createFlow") {
                setSelectedImage(webImages);
                // setSelectedName(orginalTemplateCreate?.website[0]?.name);
                // setSelectedDimension(
                //   orginalTemplateCreate?.website[0]?.dimensions
                // );
              } else {
                setSelectedImage(selectedCampaign?.advertisment_image);
                // setSelectedName(selectedCampaign?.advertisment_image[0]?.name);
                setSelectedDimension(
                  selectedCampaign?.dimensions
                );
              }
            }
          }}
        >
          {navigationFrom === "createFlow" ? (
             <img 
             src={selectedImage?.src} 
             alt="Preview" 
             onLoad={handleImageLoad}
             className="image-style"
           />
          ) : (
            <div ref={(el) => (templateRefs.current[0] = el)}>
               <img 
              src={webImages} 
              alt="Preview" 
              className="image-style"
              onLoad={handleImageLoad}

            />
            </div>
          )}
        </div>

      </div>

      
    </div>
  );
}
